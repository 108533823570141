const CheckIcon = ({
  color = "#3CD02F",
  classes = "me-2",
  height = "1em",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => {
  return (
    <svg className={classes} height={height} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3.5L4.5 7L11 0.5" stroke={color} strokeWidth="1.14286" />
    </svg>
  );
};

export default CheckIcon;

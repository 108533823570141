import React, { useState } from "react";
import ReactQuill from "react-quill";

import DstButton from "../../components/DstButton/DstButton";
import DstConfirm from "../../components/DstConfirm/DstConfirm";
import { AddTemplateFormProps, EditTemplateFormProps, SelectedTemplateProps } from "../../models/TemplateMessage";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";

export const SelectedTemplate = ({
  language,
  selectedTemplate,
  deleteTemplate,
  selectForEditing,
}: SelectedTemplateProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteConfirm = () => {
    setShowDeleteModal(false);
    deleteTemplate(selectedTemplate.id);
  };

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <h3 className="mb-3 me-4">{selectedTemplate.name}</h3>
          <div className="xs-regular lightgray-font">
            {translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.TEMPLATE_INFORMATION.CREATED_ON").replace(
              "{{DATE}}",
              formatDateHumanly(language, selectedTemplate.dt_created)
            )}
          </div>
          <div className="xs-regular lightgray-font mx-3"> - </div>
          <div className="xs-regular lightgray-font me-1">
            {selectedTemplate.dt_last_updated === null ? (
              <span>
                {translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.TEMPLATE_INFORMATION.CREATED_BY").replace(
                  "{{USER}}",
                  selectedTemplate.author.full_name
                )}
              </span>
            ) : (
              <span>
                {translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.TEMPLATE_INFORMATION.UPDATED_BY_USER_ON")
                  .replace("{{USER}}", selectedTemplate.author.full_name)
                  .replace("{{DATE}}", formatDateHumanly(language, selectedTemplate.dt_last_updated))}
              </span>
            )}
          </div>
        </div>
        <DstButton
          btnClass="text-decoration-none"
          value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.EDIT")}
          btnImageBefore={<span className="dst-icon-edit-05 pe-2 align-text-bottom text-decoration-none" />}
          variant="link"
          color="#4628dd"
          clickFunction={() => selectForEditing(selectedTemplate)}
        />
      </div>

      <div
        className="gray-font s-regular"
        id="message-content"
        dangerouslySetInnerHTML={{ __html: selectedTemplate.content }}
      />
      <DstButton
        btnClass="text-decoration-none mt-3 px-0"
        value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.DELETE")}
        btnImageAfter={<span className="dst-icon-trash-03 ps-2 align-text-bottom text-decoration-none" />}
        variant="link"
        color="#de2626"
        clickFunction={() => setShowDeleteModal(true)}
      />
      <DstConfirm
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteConfirm}
        title={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.POPUP_DELETION.CONFIRM_DELETE_TITLE")}
        messageConfirm={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.POPUP_DELETION.CONFIRM_DELETE_TEXT")}
        confirmTextButton={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.POPUP_DELETION.CONFIRM_DELETE_BTN")}
      />
    </div>
  );
};

export const AddTemplateForm = ({
  language,
  templateName,
  templateContent,
  setTemplateName,
  setTemplateContent,
  handleAddTemplateSubmit,
  handleCancel,
}: AddTemplateFormProps) => {
  return (
    <div className="add-template-form">
      <input
        type="text"
        value={templateName}
        onChange={(e) => setTemplateName(e.target.value)}
        placeholder={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.PLACEHOLDER_NAME")}
        className="form-control my-2"
      />
      <ReactQuill value={templateContent} onChange={setTemplateContent} />
      <div className="mt-2 d-flex justify-content-between">
        <DstButton
          btnClass="text-decoration-none px-0"
          value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.CANCEL")}
          variant="link"
          color="black"
          clickFunction={handleCancel}
        />
        <DstButton
          value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.CONFIRM_CREATE_TEMPLATE")}
          clickFunction={handleAddTemplateSubmit}
        />
      </div>
    </div>
  );
};

export const EditTemplateForm = ({
  selectedTemplate,
  editingTemplateContent,
  editingTemplateName,
  language,
  setEditingTemplateContent,
  setEditingTemplateName,
  submitEdit,
  handleCancel,
  showForm,
}: EditTemplateFormProps) => {
  return showForm ? (
    <div className="add-template-form">
      <input
        type="text"
        value={editingTemplateName}
        onChange={(e) => setEditingTemplateName(e.target.value)}
        placeholder={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.PLACEHOLDER_NAME")}
        className="form-control my-2"
      />
      <ReactQuill value={editingTemplateContent} onChange={setEditingTemplateContent} />
      <div className="mt-2 d-flex justify-content-between">
        <DstButton
          btnClass="text-decoration-none px-0"
          value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.CANCEL")}
          variant="link"
          color="black"
          clickFunction={handleCancel}
        />
        <DstButton
          value={translate(language, "PAGES.MESSAGES.MANAGE_TEMPLATES.USER_ACTION.SAVE_CHANGES")}
          clickFunction={submitEdit}
        />
      </div>
    </div>
  ) : null;
};

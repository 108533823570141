import { MouseEvent, useState } from "react";
import { Collapse, Dropdown, Placeholder, ProgressBar } from "react-bootstrap";

import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import CheckIcon from "../DstIcons/CheckIcon";
import CrossIcon from "../DstIcons/CrossIcon";
import ThreeDots from "../DstIcons/ThreeDots";
import "./ModuleBlock.scss";

const _getStatus = (module: any, for_class = false) => {
  let status = "In Progress";
  if (
    module.dt_finished &&
    (!module.module.evaluations.length ||
      module.module.evaluations.every((evaluation: any) =>
        evaluation.attempts.some((attempt: any) => attempt.status === "success")
      ))
  ) {
    status = "Validated";
  }

  if (!module.module.exercises.some((exercise: any) => exercise.dt_finished)) {
    status = "Not Started";
  }

  if (for_class) {
    return `status ${status.replace(/\s+/g, "-").toLowerCase()}`;
  }
  return status;
};

const _computeCompletion = (module: any) => {
  const totalCourses = module.module.exercises.length;
  const completedCourses = module.module.exercises.filter((exercise: any) => exercise.dt_finished).length;

  return totalCourses > 0 ? (completedCourses / totalCourses) * 100 : 0;
};

const _handleModuleClick = (event: MouseEvent<HTMLDivElement>, setExpanded: Function) => {
  const target = event.target as HTMLElement;
  if (target.closest && target.closest(".dropdown")) {
    return;
  }
  setExpanded((prev: boolean) => !prev);
};

const ModuleBlock = ({ module, mock = false }: any): JSX.Element => {
  const { language } = useSession();
  const [expanded, setExpanded] = useState(false);
  const completedPercent = !mock && module ? _computeCompletion(module) : 0;

  const formatDuration = (minutes: number) => {
    return `${minutes / 60 >= 1 ? `${~~(minutes / 60)}h` : ""}${("00" + (minutes % 60)).slice(-2)}${
      minutes / 60 < 1 ? "min" : ""
    }`;
  };

  const totalDuration = () =>
    formatDuration(
      module.module.exercises.reduce((total: number, course: any) => total + course.duration, 0) +
        (module.module.evaluations?.length
          ? module.module.evaluations
              .map((evaluation: any) => evaluation.duration)
              .reduce((a: number, b: number) => a + b)
          : 0)
    );

  return !mock ? (
    <div
      className={`module-block p-3 mb-3 rounded border clickable ${
        expanded ? "blue-background white-font" : "white-background"
      }`}
      onClick={(event) => _handleModuleClick(event, setExpanded)}
    >
      <div className="module-header d-flex justify-content-between align-items-center">
        <div className="s-regular">
          {translate(language, "PAGES.USER.OVERVIEW.MODULE.MODULE")}: {module.module.name}
        </div>
        <div className="d-flex">
          {module?.is_mandatory === false && (
            <span className="rounded s-bold mx-2 px-3 py-1" style={{ color: "#797E9B", background: "#E9E9E9" }}>
              {translate(language, "PAGES.USER.OVERVIEW.MODULE.OPTIONAL")}
            </span>
          )}
          <span className={`rounded s-bold px-3 py-1 ${_getStatus(module, true)}`}>{_getStatus(module)}</span>
          <Dropdown align="end">
            <Dropdown.Toggle as="div" variant="secondary" className="clickable h-100">
              <ThreeDots classes="ms-3" color={expanded ? "white" : undefined} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="s-regular">
              <Dropdown.Item className="s-bold" onClick={() => navigator.clipboard.writeText(module.module.id)}>
                {translate(language, "PAGES.USER.OVERVIEW.MODULE.MENU.COPY_ID")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="d-flex mt-3 align-items-center justify-content-between">
        <ProgressBar
          className="w-100 progress-module"
          now={completedPercent}
          aria-label={`Progress: ${completedPercent}% complete`}
        />
        <div className="ms-2 xxs-regular">{totalDuration()}</div>
      </div>
      <Collapse in={expanded}>
        <div>
          <div className="mt-3">
            <div className={`module-content s-regular ${module.module.evaluations?.length ? "pb-3" : ""}`}>
              {module.module.exercises.map((exercise: any, index: number) => (
                <div
                  key={`module-${module.module.id}-exercise-${index}`}
                  className="course d-flex justify-content-between mt-2"
                >
                  <div className="d-flex align-items-center">
                    <div className="date text-center">
                      {exercise?.dt_finished ? new Date(exercise.dt_finished).toLocaleDateString() : "-"}
                      <div className={exercise.dt_finished ? "circle solid" : "circle hollow"}></div>
                      {index !== module.module.exercises.length - 1 ? <div className="timeline"></div> : null}
                    </div>
                    <span className="title ms-3">
                      {exercise.name}
                      <span className="completed ms-2">{exercise.dt_finished ? <CheckIcon /> : undefined}</span>
                    </span>
                  </div>
                  <span className="duration xxs-regular">{exercise.duration}min</span>
                </div>
              ))}
            </div>
            <div className="module-exam px-0 s-regular">
              {module.module.evaluations.map((evaluation: any, index: number) => (
                <div
                  key={`module-${module.module.id}-evaluation-${index}`}
                  className="course d-flex justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <div className="date text-center">
                      {evaluation.attempts.length
                        ? evaluation.attempts
                            .map((elem: any) => elem.dt_started)
                            .sort()
                            .reverse()[0]
                        : "-"}
                      {index !== module.module.evaluations.length - 1 ? <div className="timeline"></div> : null}
                      <div
                        className={
                          evaluation.attempts?.some((attempt: any) => attempt.status === "success")
                            ? "circle solid"
                            : "circle hollow"
                        }
                      ></div>
                    </div>
                    <span className="title ms-3">{evaluation.name}</span>
                    <span className="attempts">
                      {evaluation.attempts
                        ?.filter((attempt: any) => ["success", "failed", "repass"].includes(attempt.status))
                        ?.map((attempt: any, index: number) => (
                          <span key={index} className="ms-2">
                            {attempt.status === "success" ? <CheckIcon classes="" /> : <CrossIcon classes="" />}
                          </span>
                        ))}
                    </span>
                  </div>
                  <span className="duration xxs-regular">{formatDuration(evaluation.duration)}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  ) : (
    <Placeholder animation="glow">
      <Placeholder
        className="module-block p-3 mb-3 rounded border"
        as="div"
        xs={12}
        style={{ height: 89 }}
      ></Placeholder>
    </Placeholder>
  );
};

export default ModuleBlock;

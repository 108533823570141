import { DateObject, getAllDatesInRange } from "react-multi-date-picker";

import ApiService from "../../services/ApiService";
import { convertDateFormat, formatDateHumanly, getAgeHumanly, getDays } from "../../services/TimeService";

function getDayOfWeek(dateStr: string, language: string) {
  const [day, month, year] = dateStr.split("/").map((num) => parseInt(num, 10));
  const date = new Date(year, month - 1, day);
  const dayOfWeek = date.getDay();

  return getDays(language)[dayOfWeek];
}

export const fetchUserLogs = (
  user_id: number,
  setUserLogs: Function,
  setMockUserLogs: Function,
  language: string,
  limit?: number
) => {
  setMockUserLogs(true);
  ApiService.get(`users/${user_id}/logs?type_exclude=notebook_time&order=desc${limit ? `&limit=${limit}` : ""}`)
    .then((response) => {
      const logs = response.data.logs.map((log: any) => ({
        ...log,
        timestamp: getAgeHumanly(language, log.timestamp, true),
      }));
      setUserLogs(logs);
    })
    .catch((error) => {
      console.error("API call failed:", error);
    })
    .finally(() => {
      setMockUserLogs(false);
    });
};

export const fetchUserLogTimeCsv = (user_id: number, username: string, setIsLoadingCsv: Function) => {
  setIsLoadingCsv(true);
  ApiService.get(`users/${user_id}/logtime/csv`)
    .then((response) => {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.setAttribute("download", `logtime_${username}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error("API call failed:", error);
    })
    .finally(() => {
      setIsLoadingCsv(false);
    });
};

export const fetchUserLogTime = (user_id: number, setUserLogTime: Function, setMockLogtime: Function) => {
  setMockLogtime(true);
  ApiService.get(`users/${user_id}/logtime`)
    .then((response) => {
      if (response.data.success && typeof response.data.logtime === "object") {
        setUserLogTime(response.data.logtime);
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    })
    .catch((error) => {
      console.error("API call failed:", error);
    })
    .finally(() => {
      setMockLogtime(false);
    });
};

export const fetchUserCohorts = (
  user_id: number,
  setUserCohorts: Function,
  setUserCohortsId: Function,
  setMockCohorts: Function
) => {
  setMockCohorts(true);
  ApiService.get(`users/${user_id}/cohorts`)
    .then((response) => {
      if (response.data.success) {
        setUserCohortsId(response.data.cohorts.map((cohort: any) => ({ id: cohort.id, name: cohort.name })));
        setUserCohorts(response.data.cohorts.map((cohort: any) => ({ name: cohort.name })));
      } else {
        console.error("Unexpected response structure:", response.data);
      }
    })
    .catch((error) => {
      console.error("API call failed:", error);
    })
    .finally(() => {
      setMockCohorts(false);
    });
};

const fetchUserCohortsLogTime = (userCohortsId: any, ts_start: number, ts_end: number, setIsLoading: Function) => {
  setIsLoading(true);
  return ApiService.get(`cohorts/${userCohortsId}/logtime/average?ts_start=${ts_start}&ts_end=${ts_end}`)
    .then((response) => {
      if (response.data.success) {
        return response.data.average_logtime || {};
      } else {
        console.error("No success in response:", response.data);
        return {};
      }
    })
    .catch((error) => {
      console.error("Failed to fetch log time:", error);
      return {};
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const handleDateChange = async (
  dateObjects: DateObject[],
  language: string,
  userName: string,
  selectedCohortId: number,
  cohortName: any,
  setBarConfig: Function,
  userLogTime: any,
  setGraphData: Function,
  setIsDateRangeSelected: Function,
  setIsLoading: Function,
  setLegendTitle: Function
) => {
  const datesArray = dateObjects.map((date) => date.toDate());

  const allDatesArray = getAllDatesInRange(dateObjects as any);

  if (allDatesArray.length >= 1 && datesArray.length === 2) {
    let start;
    let end;
    if (allDatesArray.length === 1) {
      start = datesArray[0].getTime();
      end = datesArray[0].getTime();
    } else {
      start = datesArray[0].getTime();
      end = datesArray[1].getTime();
    }
    let ts_start = Math.floor(start / 1000);
    let ts_end = Math.floor(end / 1000);
    const cohortLogtime = await fetchUserCohortsLogTime(selectedCohortId, ts_start, ts_end, setIsLoading);

    setBarConfig([{ dataKey: userName }, { dataKey: cohortName.name.toString() }]);
    const newGraphData = allDatesArray.map((date) => {
      const dateKey = convertDateFormat(date.toString());
      return {
        name: dateKey,
        [userName]: userLogTime[dateKey as any] || 0,
        [cohortName.name]: (cohortLogtime as any)[dateKey as any] || 0,
        day: getDayOfWeek(dateKey, language),
        cohortName: cohortName.name,
        cohortValue:
          (~~(cohortLogtime as any)[dateKey as any] < 1 ? "<1 min" : ~~(cohortLogtime as any)[dateKey as any]) || 0,
      };
    });
    setGraphData([...newGraphData] as any);
    setIsDateRangeSelected(true);
    if (allDatesArray.length === 1) {
      setLegendTitle(formatDateHumanly(language, dateObjects[0].toString()));
    } else {
      setLegendTitle(
        `${formatDateHumanly(language, dateObjects[0].toString())} - ${formatDateHumanly(
          language,
          dateObjects[1].toString()
        )}`
      );
    }
  }
};

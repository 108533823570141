import translate from "../../services/Translate";

export const openRecordLink = (recordLink: any, language: string) => {
  if (window.confirm(translate(language, "PAGES.USER.MEETING.BUTTON.POPUP_MESSAGE"))) {
    navigator.clipboard.writeText(recordLink.password);
    window.open(recordLink.share_url, "_blank");
  }
};

export const getFontClass = (isPast: boolean, attended: boolean | string): string => {
  if (isPast) {
    return [true, "Justified absence", "Forgotten signature"].includes(attended) ? "green-font" : "red-font";
  }
  return "yellow-font";
};

export const renderAttendance = (isPast: boolean, attended: boolean | string, language: string) => {
  if (!isPast) return null;
  if (typeof attended === "boolean") {
    return attended
      ? translate(language, "PAGES.USER.MEETING.ATTENDANCE.PRESENT")
      : translate(language, "PAGES.USER.MEETING.ATTENDANCE.ABSENT");
  } else {
    return translate(language, `PAGES.USER.MEETING.ATTENDANCE.${attended.toUpperCase().replace(" ", "_")}`);
  }
};

export const getAttendanceIconClass = (attended: boolean | string) => {
  if (typeof attended === "boolean") {
    return attended ? "dst-icon-user-check-02" : "dst-icon-user-x-02";
  } else {
    switch (attended.toLowerCase()) {
      case "forgotten signature":
        return "dst-icon-user-plus-02";
      case "justified absence":
        return "dst-icon-user-right-02";
    }
  }
  return "";
};

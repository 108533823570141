import React, { useEffect, useRef, useState } from "react";

import DstButton from "../../components/DstButton/DstButton";
import DstTabsVertical from "../../components/DstTabsVertical/DstTabsVertical";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { LoadedPermissionsData } from "./Permissions.d";
import {
  closeRoutePopup,
  createNewRole,
  fetchPermissionsData,
  getPermissionsWithGodMode,
} from "./Permissions.functions";
import "./Permissions.scss";
import { routePopup } from "./components/Popups";
import { buildTabs } from "./components/Tabs";

const Permissions = () => {
  const { language } = useSession();
  const [activeTab, setActiveTab] = useState<string>("");
  const [roles, setRoles] = useState<{ id: string; description: string; users?: number[] }[]>([]);
  const [permissions, setPermissions] = useState<{ [route: string]: any }>({});
  const [tags, setTags] = useState([]);
  const [showPopups, setShowPopups] = useState<{ [key: string]: number | string } | null>(null);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);
  const [roleUsers, setRoleUsers] = useState<{ [roleId: string]: number[] }>({});
  const [loaded, setLoaded] = useState<LoadedPermissionsData>({
    roles: false,
    permissions: false,
    tags: false,
    rolesUsers: {},
  });
  const [displayCount, setDisplayCount] = useState(10);
  const [rolePermissions, setRolePermissions] = useState<{ [roleId: string]: string[] }>({});
  const activeTabRef = useRef(activeTab);

  const _fetchPermissionsData = () =>
    fetchPermissionsData(
      setRoles,
      setRoleUsers,
      setRolePermissions,
      setPermissions,
      setTags,
      setLoaded,
      setActiveTab,
      activeTabRef
    );

  useEffect(() => {
    _fetchPermissionsData();
    activeTabRef.current = activeTab;
  }, [activeTab]);

  return (
    <div id="permissions">
      <div className="d-flex justify-content-between px-3 pt-3">
        <h1 className="mt-3">{translate(language, "PAGES.PERMISSIONS.TITLE")}</h1>
        <img src="/assets/images/person-h-f.svg" alt="A man and a woman standing next to each other" />
      </div>
      <div className="border-top">
        <DstTabsVertical
          titleTabs={translate(language, "PAGES.PERMISSIONS.TAB_TITLE")}
          tabsData={buildTabs(
            language,
            roles,
            tags,
            permissions,
            roleUsers,
            loaded,
            selectedTags,
            setSelectedTags,
            selectedMethods,
            setSelectedMethods,
            displayCount,
            setDisplayCount,
            rolePermissions,
            setRolePermissions,
            activeTab,
            showPopups,
            setShowPopups,
            _fetchPermissionsData
          )}
          activeKey={activeTab}
          onSelect={(key: string | null) => setActiveTab(key || "")}
          customButtonComponent={
            <DstButton
              value={translate(language, "PAGES.PERMISSIONS.CREATE_ROLE_BUTTON_TITLE")}
              variant="light"
              backgroundColor="#F2F2F2"
              color="black"
              btnClass="w-fit my-3 custom-btn-role m-3"
              btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
              clickFunction={() => createNewRole(language, _fetchPermissionsData)}
            />
          }
          paddingTop
          fullHeight
          bordeRight
        />
        {routePopup(language, showPopups, () => closeRoutePopup(setShowPopups), getPermissionsWithGodMode(permissions))}
      </div>
    </div>
  );
};

export default Permissions;

import React from "react";
import { Nav, Navbar, Placeholder } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useSession } from "../../contexts/SessionContext";
import { NavbarLeftProps } from "./NavbarLeft.d";
import "./NavbarLeft.scss";

const NavbarLeft = ({ menu }: NavbarLeftProps) => {
  const { username, image_url, role, loaded } = useSession();

  return (
    <Navbar id="navbar-left" className="px-2 py-4 border-end w-fit align-items-start">
      <Navbar.Toggle aria-controls="basic-navbar-nav-left" />
      <Navbar.Collapse id="navbar-nav-left">
        <Nav className="d-block m-bold w-100">
          <div className="d-flex min-h-50" id="avatar-section">
            <div className="align-self-center">
              {loaded ? (
                <img
                  src={image_url || "/assets/images/user.jpg"}
                  alt="User profile"
                  height="30px"
                  width="30px"
                  className="mb-1 img-fluid"
                />
              ) : (
                <Placeholder animation="glow">
                  <Placeholder height="30px" width="30px" as="img" />
                </Placeholder>
              )}
            </div>
            <div className="user-details">
              <span className="m-bold ms-2 w-100">{username}</span>
              <div id="tag" className="ms-2 mb-2">
                {role}
              </div>
            </div>
          </div>
          <hr />
          {menu.map((item, index) => (
            <div key={index} className="mb-0 w-fit-content">
              <NavLink
                to={item.to}
                className={({ isActive }) =>
                  `d-flex align-items-center justify-content-center p-2 rounded ${isActive ? "active" : ""}`
                }
              >
                <div className="text-center menu-icon">
                  <item.Icon {...item.iconProps} />
                </div>
                <div className="nav-text">
                  <span className="ms-1">{item.label}</span>
                </div>
              </NavLink>
            </div>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarLeft;

interface Evaluation {
  eval_id: number;
  nom_de_l_eval: string;
  contenu_de_l_eval: string;
  learner_name: string;
  learner_id: string;
  learner_lang: string;
  cohort_name: string;
  program_manager: string;
  cohort_leader: string;
  exam_status: string;
  ai_prevision_score: string;
  number_of_attempts: number;
  duration: string;
  total_duration: string;
  exam_name: string;
  exam_mail_send_date: string;
  exam_mail_opening_date: string;
  exam_start_datetime: string;
  exam_end_datetime: string;
}

const evaluations: Evaluation[] = [
  {
    eval_id: 1,
    nom_de_l_eval: "Évaluation HTML et CSS",
    contenu_de_l_eval: `
        <h1>HTML Ipsum Presents</h1>
  
        <p><strong>Pellentesque habitant morbi tristique</strong> senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. <em>Aenean ultricies mi vitae est.</em> Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum sed, <code>commodo vitae</code>, ornare sit amet, wisi. Aenean fermentum, elit eget tincidunt condimentum, eros ipsum rutrum orci, sagittis tempus lacus enim ac dui. <a href="#">Donec non enim</a> in turpis pulvinar facilisis. Ut felis.</p>
  
        <h2>Header Level 2</h2>
  
        <ol>
          <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
          <li>Aliquam tincidunt mauris eu risus.</li>
        </ol>
  
        <blockquote><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus magna. Cras in mi at felis aliquet congue. Ut a est eget ligula molestie gravida. Curabitur massa. Donec eleifend, libero at sagittis mollis, tellus est malesuada tellus, at luctus turpis elit sit amet quam. Vivamus pretium ornare est.</p></blockquote>
  
        <h3>Header Level 3</h3>
  
        <ul>
          <li>Lorem ipsum dolor sit amet, consectetuer adipiscing elit.</li>
          <li>Aliquam tincidunt mauris eu risus.</li>
        </ul>
  
        <pre><code>
        #header h1 a {
          display: block;
          width: 300px;
          height: 80px;
        }
        </code></pre>
        <pre><code>
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡴⠊⣁⣤⣈⡉⢦⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⣠⠖⠒⢒⠲⣄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⡏⢀⣾⣿⣿⣾⡽⣦⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⢀⡾⠃⣠⣶⣦⣄⠀⡑⢄⠀⠀⠀⠀⣀⡄⠀⠀⢀⣀⡤⠤⣤⣤⡀⣤⣿⠀⣾⣥⣿⣿⣿⣿⣿⣼⡆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⣾⠁⣼⣿⣿⡧⣽⣷⡜⣌⢳⡀⣠⠞⢁⣠⠔⠚⠋⠀⠀⣠⠟⠋⣸⣿⡇⢠⣿⣿⣿⣿⣿⣿⣿⡿⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⣿⢸⣿⣿⣿⡿⣦⣿⣿⡌⢆⢻⣿⣶⢋⣠⠤⠄⡤⢄⣬⢀⣘⣿⣿⣋⠂⣼⣿⣥⣾⣿⣿⣿⣿⠇⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⣿⠘⣿⣿⣿⣿⣬⣋⣿⣿⣀⠿⣿⣶⣏⢖⣤⡀⢈⠙⣷⣆⣸⡿⣿⡻⣿⡿⠛⢿⣿⣿⣿⣿⣿⢰⡇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⢹⣇⠹⣿⣿⣿⣿⣿⠟⠋⠅⠘⠛⣿⣿⣿⣯⣿⢺⣾⣿⣿⡟⢃⣿⣴⣻⣟⡈⠀⠙⢻⣿⣿⠏⣾⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⢻⣆⣿⣿⣿⡿⠋⠀⠀⠀⢀⡠⠬⠾⠮⢿⣿⡉⢻⣩⣿⣧⠀⠉⢿⣿⣏⣀⣀⣀⠀⠙⢏⢠⣿⡿⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠻⣧⠙⣿⠀⠀⢀⡴⠊⠁⣀⣤⣤⣤⣼⣿⠈⠙⠛⣿⣿⡄⠀⢸⣿⣿⣿⠿⣿⣿⣦⡀⢻⡿⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⢶⣿⣦⠎⣠⢢⠊⠀⢠⣾⣿⡿⠛⠛⢿⣿⣆⠀⣸⣿⣿⣿⡀⣿⣿⣿⣥⣤⡈⢻⣿⣿⡀⢙⣆⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
⣄⠀⠀⠀⠘⢻⣿⡁⣰⠟⠁⠀⣰⣿⣿⠏⢠⣾⡟⢛⣿⣿⡄⣿⣿⣿⣿⣿⣿⣿⣿⣧⣠⣧⠸⣿⣿⣷⡴⣍⠀⠀⠀⢀⣀⠄⠀⠀⠀⠀
⠀⠉⠙⠒⠤⣼⣏⣾⠟⠀⠀⢠⣿⣿⡟⠀⣿⣿⣷⣾⣿⣿⣿⠸⣿⣿⣿⣷⣿⣿⣿⣿⣿⠏⣠⣿⣿⣿⣿⣷⡧⠖⠊⠁⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⢠⢼⡿⠛⠢⢤⣴⣿⣿⣟⣻⣦⣘⣿⣿⣿⣿⣿⣿⡀⣿⣿⣿⡅⢹⣿⣿⣿⣿⣾⣿⣿⣿⣿⣿⣿⣦⠀⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⣠⡿⠁⠀⠀⣠⣶⣶⣍⣛⢿⣿⣿⣿⣿⣿⣿⡿⠟⠃⢸⡿⣿⣷⠀⠉⠙⠿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⠂⠐⠀⠐⠀⠀⠀⠒
⣀⣀⣀⣼⣋⣀⣀⣠⣾⣿⣿⣿⣿⣿⣷⣯⣝⡿⢿⡿⠋⠀⠀⣀⣼⣿⣿⠛⠛⢛⣷⣦⠈⢿⣶⣿⣿⣿⣿⣿⣿⣿⣷⠀⠀⠀⠀⠀⠀⠀
⠀⠀⢫⡶⠋⠀⣽⣿⣿⣯⣭⣽⣿⣛⣛⡛⠛⢿⠏⠀⠀⠀⣼⣿⣿⣿⣿⣿⣿⣿⣿⣿⡇⢸⠛⣿⣿⣿⣿⣿⣿⣿⡿⠀⠀⠀⠒⠒⠒⠒
⠀⠀⠘⢦⡀⠰⣿⣿⣿⣿⣿⣿⣿⣿⣿⣿⣷⡎⠀⠀⠀⠀⢿⣿⣿⣿⣿⣿⣿⣿⣿⣿⠃⠎⠀⠈⣽⣿⣿⣿⡿⠛⠀⠀⠀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠈⠑⠮⣿⣿⣿⣿⣿⡿⣟⣻⣭⡞⠁⠀⡀⠀⠀⠈⠛⠛⠛⠿⠟⠛⠛⠉⠀⢀⠀⠀⢀⣿⣿⣿⠏⠉⠓⠢⢄⡀⠀⠀⠀⠀⠀
⠀⠀⠀⠀⠀⠘⡍⢈⣹⣿⣯⣶⣿⣿⣿⠋⢀⠔⠋⠀⡠⠊⡁⠀⠀⠀⠀⠒⠀⠀⠀⠀⢀⣧⣤⣾⣿⣟⠁⠀⠀⠀⠀⠀⠈⠁⠀⠀⠀⠀
⠀⠀⠀⠀⠀⢀⡴⠻⣧⡈⠉⠛⠿⠿⣿⣶⣅⣀⣀⣀⣀⣀⣅⣀⣄⣀⣀⣀⣤⣴⣾⣿⣿⣿⣿⣿⡿⢁⡀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣠⣾
⠀⠀⠀⡠⠞⠁⠀⠀⠸⣽⠦⠀⠀⡠⠋⠀⠈⠛⠻⠛⠿⠿⠿⣿⣟⣟⢿⣟⣿⣿⣿⣿⣿⣿⣿⣿⣿⣯⡶⣺⣿⣿⣿⣷⣶⣶⣶⣿⣿⣿
⠀⡠⠊⠀⠀⠀⠀⠀⢀⣨⣵⣄⡈⠀⠀⢦⣄⠀⠀⠀⠀⠀⠀⠘⠛⠟⢻⠯⠩⣌⣿⣿⣿⠿⣿⣿⣿⠿⣠⣿⣿⣿⢻⣿⡟⣻⡿⢻⣻⣿
⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⡼⢿⣦⡀⢄⠀⠉⠛⠶⢤⣤⣀⡀⢀⠀⠀⠀⢀⠀⠉⠋⡉⣻⠇⣺⣿⠋⢴⣿⣿⣿⡇⣻⡏⢀⣿⡇⠀⢸⣿
⠀⠀⠀⠀⠀⠀⠀⢀⡠⠊⠀⠀⠙⢻⣶⣭⣦⣀⣀⠀⠀⠀⠉⠉⠉⠀⠀⠈⠉⠲⢴⣿⣿⢾⣿⡏⢼⣿⣿⣿⣿⢡⣿⡟⣿⣿⣧⠃⢸⣿
        </code></pre>

      `,
    learner_name: "Jean Dupont",
    learner_id: "1",
    learner_lang: "fr-FR",
    cohort_name: "Cohorte 2024",
    program_manager: "Marie Claire",
    cohort_leader: "Paul Martin",
    exam_status: "Completed",
    ai_prevision_score: "18/20",
    number_of_attempts: 1,
    duration: "0:45:03",
    total_duration: "1:00:00",
    exam_name: "html_css_eval_de_sprint1",
    exam_mail_send_date: "2024-11-18",
    exam_mail_opening_date: "2024-11-19",
    exam_start_datetime: "2024-11-20T18:29",
    exam_end_datetime: "2024-11-20T19:10",
  },
  {
    eval_id: 2,
    nom_de_l_eval: "Évaluation JavaScript",
    contenu_de_l_eval: `
        <html>
          <head><title>Évaluation JavaScript</title></head>
          <body>
            <h1>Bienvenue à l'évaluation JavaScript</h1>
            <p>Veuillez compléter les exercices JavaScript ci-dessous.</p>
          </body>
        </html>
      `,
    learner_name: "Sophie Martin",
    learner_id: "3",
    learner_lang: "en-US",
    cohort_name: "Cohorte 2024",
    program_manager: "Marie Claire",
    cohort_leader: "Paul Martin",
    exam_status: "Pending",
    ai_prevision_score: "17/20",
    number_of_attempts: 2,
    duration: "1:00:00",
    total_duration: "1:30:00",
    exam_name: "javascript_eval_de_sprint2",
    exam_mail_send_date: "2024-12-01",
    exam_mail_opening_date: "2024-12-02",
    exam_start_datetime: "2024-12-03T14:00",
    exam_end_datetime: "2024-12-03T15:30",
  },
  {
    eval_id: 3,
    nom_de_l_eval: "Évaluation React",
    contenu_de_l_eval: `
        <html>
          <head><title>Évaluation React</title></head>
          <body>
            <h1>Bienvenue à l'évaluation React</h1>
            <p>Créez une application React simple selon les spécifications.</p>
          </body>
        </html>
      `,
    learner_name: "Alice Bernard",
    learner_id: "333",
    cohort_name: "Cohorte 2024",
    learner_lang: "en-US",
    program_manager: "Marie Claire",
    cohort_leader: "Paul Martin",
    exam_status: "In Progress",
    ai_prevision_score: "19/20",
    number_of_attempts: 7,
    duration: "0:50:30",
    total_duration: "1:00:00",
    exam_name: "react_eval_de_sprint3",
    exam_mail_send_date: "2024-12-10",
    exam_mail_opening_date: "2024-12-11",
    exam_start_datetime: "2024-12-12T10:30",
    exam_end_datetime: "2024-12-12T11:30",
  },
];

export default evaluations;

export const userMocks = [
  {
    id: "4503",
    name: "Mike Attal",
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/preprod.dst-user-profile-pictures/a263b5319896ff234fca8f8d8ca80459.jpeg",
  },
  {
    id: "16",
    name: "Charles Parmentier",
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/preprod.dst-user-profile-pictures/1d2ea29effef1699b4940d1177aebdee.jpeg",
  },
  {
    id: "11000",
    name: "Syphax Kerrouche",
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/preprod.dst-user-profile-pictures/e6d743796859887b32c7cf90c24193d8.jpeg",
  },
  {
    id: "9234",
    name: "Julie KATZ",
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/preprod.dst-user-profile-pictures/a08e9a9076c29f5e4262f44872fb5262.jpg",
  },
  {
    id: "2796",
    name: "Benjamin Dubois",
    imageUrl:
      "https://s3.eu-west-1.amazonaws.com/preprod.dst-user-profile-pictures/bf494ad0fcc7513d2431bb7b88e24b24.gif",
  },
];

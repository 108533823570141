export const getIntensityColor = (intensity: string) => {
  switch (intensity) {
    case "continuous":
      return { bg: "warning", text: "dark" };
    case "bootcamp":
      return { bg: "primary" };
    case "tracks":
      return { bg: "danger" };
    default:
      return { bg: "secondary" };
  }
};

import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const fetchEvaluations = (userCertifs: any[], setEvaluations: Function, setLoadedCertif: Function) => {
  ApiService.get("evaluations")
    .then((response) => {
      const evaluations: any[] = response.data.evaluations;
      const userCertificationsNames = new Set(userCertifs.map((cert) => cert.certification_name));
      const availableEvaluations = evaluations.filter(
        (evaluation: any) => !userCertificationsNames.has(evaluation.name)
      );
      setEvaluations(availableEvaluations);
      setLoadedCertif(true);
    })
    .catch((error) => {
      console.error("Failed to fetch evaluations or certifications", error);
    });
};

export const refreshUserEvaluation = (
  user: any,
  evaluations: any[],
  setEvaluations: Function,
  refreshUserCertifications: Function
) => {
  try {
    const userCertificationsNames = new Set(user.user_certifs.map((cert: any) => cert.certification_name));
    const availableEvaluations = evaluations.filter((evaluation: any) => !userCertificationsNames.has(evaluation.name));
    setEvaluations(availableEvaluations);
    refreshUserCertifications();
  } catch (error) {
    console.error("Failed to fetch certifications", error);
  }
};

export const handleSaveEvaluations = (
  user: any,
  selectedEvaluations: any[],
  setButtonClicked: Function,
  evaluations: any[],
  setEvaluations: Function,
  setShowEvaluationsModal: Function,
  setSelectedEvaluations: Function,
  refreshUserEvaluation: Function,
  refreshUserCertifications: Function,
  language: any
) => {
  if (selectedEvaluations.length > 0 && user) {
    setButtonClicked(true);
    const postPromises = selectedEvaluations.map((evaluation) =>
      ApiService.post(`/users/${user.id}/certifications`, {
        evaluation_id: evaluation.id,
      })
    );

    Promise.allSettled(postPromises)
      .then((responses) => {
        const failedRequests = responses.filter((response) => response.status === "rejected");
        if (failedRequests.length > 0) {
          console.error(translate(language, "PAGES.USER.USER_BLOCK.ERROR_CERTIF_ALREADY_GIVEN"), failedRequests);
          alert(translate(language, "PAGES.USER.USER_BLOCK.ERROR_CERTIF_ALREADY_GIVEN"));
        } else {
          alert(translate(language, "PAGES.USER.USER_BLOCK.CERTIF_SUCCESS_SAVE"));
        }
        setSelectedEvaluations([]);
        setShowEvaluationsModal(false);
        refreshUserEvaluation(user, evaluations, setEvaluations, refreshUserCertifications);
        setButtonClicked(false);
      })
      .catch((error) => {
        console.error(translate(language, "PAGES.USER.USER_BLOCK.ERROR_OTHER"));
        alert(translate(language, "PAGES.USER.USER_BLOCK.ERROR_OTHER"));
        setButtonClicked(false);
      });
  } else {
    console.error(translate(language, "PAGES.USER.USER_BLOCK.ERROR_OTHER"));
    setButtonClicked(false);
  }
};

export const fetchProgressReport = (
  language: string,
  userId: number,
  params: {
    cohortId?: number;
    cursusName?: string;
  },
  setLoadingReport: Function,
  successCallBack: Function
) => {
  if (!(params.cohortId && params.cursusName)) {
    alert(translate(language, "PAGES.USER.USER_BLOCK.REPORT.ERROR_NEED_OPTIONS"));
    return;
  }
  setLoadingReport(true);
  ApiService.postDownload(`users/${userId}/${params.cohortId}/progress`, {
    cursus_name: params.cursusName,
  })
    .then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${userId}_${params.cohortId}_progress_report.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      successCallBack();
    })
    .catch((err) => {
      console.error(err);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => setLoadingReport(false));
};

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Placeholder, Tooltip } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import DstTabs from "../../../../components/DstTabs/DstTabs";
import { isPast } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { CohortSprintDetails, cohortSprintModulesType } from "./CohortSprint.d";
import {
  allMandatoryModulesValidated,
  fetchModules,
  fetchSprint,
  getSprintsAndModules,
  saveSprints,
  transformSprintData,
} from "./CohortSprint.function";
import "./CohortSprint.scss";
import CohortSprintPopup from "./CohortSprintPopup/CohortSprintPopup";

const CohortSprint = ({ cohortId, language }: { cohortId: string; language: string }) => {
  const [sprintData, setSprintData] = useState<CohortSprintDetails[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [selectedSprint, setSelectedSprint] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingModules, setLoadingModules] = useState(true);
  const [filteredData, setFilteredData] = useState<CohortSprintDetails[]>([]);

  const loadSprintData = useCallback(() => {
    setLoading(true);
    fetchSprint(
      cohortId,
      language,
      (sprints: any) => {
        const transformedData = transformSprintData(sprints);
        setSprintData(transformedData);
        if (transformedData.length > 0) {
          const currentSprint = transformedData.find((data: any) => data.cohortSprints[0].current_sprint);
          setSelectedSprint(
            currentSprint ? currentSprint.cohortSprints[0].name : transformedData[0].cohortSprints[0].name
          );
        }
      },
      () => {
        setLoading(false);
      }
    );
  }, [cohortId, language]);

  useEffect(() => {
    loadSprintData();
  }, [loadSprintData]);

  const filteredDataBySprint = useCallback(
    (sprintName: string) => {
      return sprintData.filter((item) => item.cohortSprints.some((sprint) => sprint.name === sprintName));
    },
    [sprintData]
  );

  const sprintsAndModules = useMemo(() => getSprintsAndModules(sprintData), [sprintData]);

  const columns = useCallback(
    (sprintName: string) => [
      { name: translate(language, "PAGES.COHORT.SPRINT.USER_ID"), key: "id" },
      { name: translate(language, "PAGES.COHORT.SPRINT.LAST_NAME"), key: "lastName" },
      { name: translate(language, "PAGES.COHORT.SPRINT.FIRST_NAME"), key: "firstName" },
      { name: translate(language, "PAGES.COHORT.SPRINT.EMAIL"), key: "email" },
      { name: translate(language, "PAGES.COHORT.SPRINT.MANDATORY_EXAMS"), key: "mandatoryExams" },
      ...sprintsAndModules[sprintName].map(({ id, isMandatory, moduleName }) => ({
        name: (
          <React.Fragment>
            {moduleName}
            {!isMandatory && (
              <OverlayTrigger
                key={`tooltip-${id}`}
                placement="top"
                overlay={<Tooltip id={`tooltip-${id}`}>{translate(language, "PAGES.COHORT.SPRINT.OPTIONAL")}</Tooltip>}
              >
                <i className="dst-icon-file-plus-02 ms-1 align-text-bottom" />
              </OverlayTrigger>
            )}
          </React.Fragment>
        ),
        key: `sprint_${sprintName}_module_${id}`,
        render: (line: CohortSprintDetails) => {
          const sprintData = line.cohortSprints.find((sprint) => sprint.name === sprintName);
          if (sprintData) {
            const moduleData = sprintData.sprintModules.find((module) => module.id === id);
            if (moduleData) {
              const { totalExercises, completedExercises } = moduleData.moduleExercisesInfos;
              return `${completedExercises}/${totalExercises}`;
            }
          }
          return "N/A";
        },
        stylishCell: (line: CohortSprintDetails) => {
          const sprintData = line.cohortSprints.find((sprint) => sprint.name === sprintName);
          const moduleData = sprintData?.sprintModules.find((module) => module.id === id);
          const { totalExercises, completedExercises } = moduleData?.moduleExercisesInfos || {
            totalExercises: 0,
            completedExercises: 0,
          };
          return {
            backgroundColor: completedExercises === totalExercises ? "#64C87A" : "white",
            color: "black",
          };
        },
        stylishTitle: () => (isMandatory ? {} : { color: "dimgray" }),
      })),
    ],
    [language, sprintsAndModules]
  );

  useEffect(() => {
    if (selectedSprint) {
      setFilteredData(filteredDataBySprint(selectedSprint));
    }
  }, [selectedSprint, filteredDataBySprint]);

  const preProcessedData = useMemo(
    () =>
      sprintData
        .filter((item) => item.cohortSprints.some((sprint) => sprint.name === selectedSprint))
        .map((item) => ({
          ...item,
          columns: columns(selectedSprint || ""),
        })),
    [sprintData, selectedSprint, columns]
  );

  const [showPopup, setShowPopup] = useState(false);
  const [activeKey, setActiveKey] = useState("sprint-0");

  if (loading) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
        </div>
      </Placeholder>
    );
  }

  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="me-3 align-content-center mb-0">{translate(language, "PAGES.COHORT.SPRINT.TITLE")}</h2>
          <DstTableSearch
            data={preProcessedData}
            searchableColumns={["lastName", "firstName", "email", "active"]}
            onSearch={setFilteredData}
          />
        </div>
        <DstButton
          btnImageBefore={<i className="dst-icon-edit-02 me-2 align-text-bottom" />}
          value={translate(language, "PAGES.COHORT.SPRINT.BUTTON_MANAGE")}
          variant="light"
          clickFunction={() => {
            setShowPopup(true);
            fetchModules(setModules, () => {
              setLoadingModules(false);
            });
          }}
        />
      </div>
      <div className="cohort-sprint-table-container">
        {Object.keys(sprintsAndModules).length > 0 ? (
          <DstTabs
            activeKey={selectedSprint || ""}
            onSelect={setSelectedSprint}
            variant="primary"
            tabsData={Object.keys(sprintsAndModules).map((sprintName, index) => {
              const sprintInfo = sprintsAndModules[sprintName][0];
              return {
                id: sprintName,
                key: `tab-${sprintName}`,
                title: (
                  <React.Fragment>
                    <span className="badge bg-primary me-2">S{index + 1}</span>
                    {sprintName}
                    {isPast(sprintInfo.dt_end) && (
                      <i className="dst-icon-check-circle-broken darkgreen-font ms-2 align-text-bottom"></i>
                    )}
                  </React.Fragment>
                ),
                content: (
                  <DstTable
                    key={`table-${sprintName}`}
                    data={filteredData.length > 0 ? filteredData : preProcessedData}
                    stylishLine={(line: CohortSprintDetails) =>
                      allMandatoryModulesValidated(line.cohortSprints[0].sprintModules)
                        ? { backgroundColor: "#64C87A" }
                        : {}
                    }
                    columns={columns(sprintName)}
                  />
                ),
              };
            })}
          />
        ) : (
          <div className="mt-3 text-muted">{translate(language, "PAGES.COHORT.SPRINT.NO_SPRINTS_FOUND")}</div>
        )}
      </div>
      <CohortSprintPopup
        language={language}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        sprintsAndModules={sprintsAndModules}
        loadingModules={loadingModules}
        modules={modules}
        handleSaveSprints={(
          index: number,
          inputValues: string[],
          sprintNames: string[],
          startDates: string[],
          endDates: string[],
          optionalStatus: any[],
          sprintsAndModules: cohortSprintModulesType,
          setSprintNames: Function,
          language: string,
          callback: Function
        ) =>
          saveSprints(
            cohortId,
            index,
            inputValues,
            sprintNames,
            startDates,
            endDates,
            optionalStatus,
            sprintsAndModules,
            setSprintNames,
            language,
            () => {
              callback();
              loadSprintData();
            }
          )
        }
      />
    </div>
  );
};

export default CohortSprint;

const Clock = ({ color = "#8F8FA8", classes = "me-2" }: { color?: string; classes?: string }) => (
  <svg className={classes} height="1em" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.21899 0C4.56238 0 3.9122 0.129329 3.30558 0.380602C2.69895 0.631876 2.14775 1.00017 1.68346 1.46447C0.745778 2.40215 0.218994 3.67392 0.218994 5C0.218994 6.32608 0.745778 7.59785 1.68346 8.53553C2.14775 8.99983 2.69895 9.36812 3.30558 9.6194C3.9122 9.87067 4.56238 10 5.21899 10C6.54508 10 7.81685 9.47322 8.75453 8.53553C9.69221 7.59785 10.219 6.32608 10.219 5C10.219 4.34339 10.0897 3.69321 9.83839 3.08658C9.58712 2.47995 9.21882 1.92876 8.75453 1.46447C8.29024 1.00017 7.73904 0.631876 7.13241 0.380602C6.52578 0.129329 5.8756 0 5.21899 0ZM7.31899 7.1L4.71899 5.5V2.5H5.46899V5.1L7.71899 6.45L7.31899 7.1Z"
      fill={color}
    />
  </svg>
);

export default Clock;

import { useState } from "react";
import { Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import LiveLister from "../../components/Lister/LiveLister";
import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { getIntensityColor } from "./Cohorts.function";

export const Cohorts = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const columns = [
    { header: translate(language, "PAGES.COHORTS.ID"), key: "id", sortable: true },
    { header: translate(language, "PAGES.COHORTS.NAME"), key: "name", sortable: true },
    { header: translate(language, "PAGES.COHORTS.COHORT_LEADER"), key: "cohort_leader" },
    { header: translate(language, "PAGES.COHORTS.PROGRAM_MANAGER"), key: "program_manager" },
    {
      header: translate(language, "PAGES.COHORTS.SIZE"),
      key: "users_count",
      render: (item: any) => (
        <span>
          {item.users_count} <i className="dst-icon-users-01"></i>
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.START_DATE"),
      key: "dt_start",
      sortable: true,
      render: (item: any) => (
        <span title={formatDateHumanly(language, item.start_date, true)}>
          {formatDateHumanly(language, item.start_date)}
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.END_DATE"),
      key: "dt_end",
      sortable: true,
      render: (item: any) => (
        <span title={formatDateHumanly(language, item.end_date, true)}>
          {formatDateHumanly(language, item.end_date)}
        </span>
      ),
    },
    {
      header: translate(language, "PAGES.COHORTS.INTENSITY"),
      key: "intensity",
      render: (item: any) => (
        <Badge pill {...getIntensityColor(item.intensity)}>
          {item.intensity}
        </Badge>
      ),
    },
  ];

  return (
    <div className="m-5">
      <div className="d-flex w-fit align-items-center justify-content-between mb-3">
        <h2 className="mb-0 me-3">Cohorts</h2>
        <ListerSearch setSearchQuery={setSearchQuery} timeDelay={500} showSearchIcon />
      </div>
      <LiveLister
        remote={`cohorts?fetch_users_count=true${searchQuery ? `&search=${searchQuery.toLowerCase()}` : ""}`}
        remoteKey="cohorts"
        remoteTransform={(cohort: any) => ({
          id: cohort.id,
          name: cohort.name,
          cohort_leader: cohort.cohort_leader.full_name,
          program_manager: cohort.program_manager?.full_name || "N/A",
          start_date: cohort.dt_start,
          end_date: cohort.dt_end,
          intensity: cohort.intensity,
          users_count: cohort.users_count,
        })}
        columns={columns}
        additionalButton={(item: any) => (
          <span className="clickable xs-regular" onClick={() => navigate(`/cohort/${item.id}`)}>
            <span className="dst-icon-arrow-narrow-right" style={{ fontSize: "15px" }} />
          </span>
        )}
        showHeader
        pagination
        itemsPerPage={20}
      />
    </div>
  );
};

export default Cohorts;

const CrossIcon = ({ color = "#D02F2F", classes = "me-2" }: { color?: string; classes?: string }) => {
  return (
    <svg className={classes} height="1em" viewBox="0 0 1024 1024" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M960 170.56L869.44 80 512 437.44 154.56 80 64 170.56 421.44 528 64 885.44l90.56 90.56L512 618.56 869.44 976 960 885.44 602.56 528 960 170.56z"
        stroke={color}
        strokeWidth="1.14286"
      />
    </svg>
  );
};

export default CrossIcon;

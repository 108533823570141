import React, { useEffect, useState } from "react";

import { ItemData } from "../../models/Selector";
import DstButton from "../DstButton/DstButton";
import Chevron from "../DstIcons/Chevron";
import "./Selector.scss";

const Selector = ({
  items,
  onItemSelected,
  enableDropDown = true,
}: {
  items: ItemData[];
  onItemSelected?: (item: ItemData) => void;
  enableDropDown?: boolean;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (items.length > 0 && selectedIndex !== null) {
      onItemSelected?.(items[selectedIndex]);
    }
    // eslint-disable-next-line
  }, [items]);

  const updateIndex = (newIndex: number) => {
    setSelectedIndex(newIndex);
    onItemSelected?.(items[newIndex]);
  };

  const handlePrevious = () => {
    if (selectedIndex > 0) {
      updateIndex(selectedIndex - 1);
    }
  };

  const handleNext = () => {
    if (selectedIndex < items.length - 1) {
      updateIndex(selectedIndex + 1);
    }
  };

  const selectedItem = items[selectedIndex];

  return (
    <div>
      <div>
        <DstButton
          value=""
          aria-label="Previous Cohort"
          btnImageAfter={<Chevron orientation="left" color="white" classes="" height="21px" />}
          btnSize="sm"
          clickFunction={handlePrevious}
          disabled={selectedIndex <= 0}
          btnClass="btn-selector-left"
        />

        {enableDropDown ? (
          <select
            className="p-1 select-selector text-center xs-regular"
            value={selectedItem ? selectedIndex : ""}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              updateIndex(parseInt(event.target.value, 10));
            }}
          >
            {items.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </select>
        ) : (
          <span className="p-2 select-selector text-center xs-regular">
            {selectedItem ? selectedItem.name : "No selection"}
          </span>
        )}
        <DstButton
          value=""
          aria-label="Next Cohort"
          btnImageAfter={<Chevron orientation="right" color="white" classes="" height="21px" />}
          btnSize="sm"
          clickFunction={handleNext}
          disabled={selectedIndex >= items.length - 1}
          btnClass="btn-selector-right"
        />
      </div>

      {selectedItem && selectedItem.content}
    </div>
  );
};

export default Selector;

import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";

export const RECIPIENT_PAGING_SIZE = 20;

export const _truncateMessageContent = (content: string): string => {
  content = content.replace(/<\/p>/g, "</p> ").replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gm, "");
  if (content.length > 100) content = content.slice(0, 99) + "…";
  return content;
};

const _listReceivers = (language: string, message: any): string => {
  let cohort_receivers = 0;

  const parameters = JSON.parse(message.parameters.replace(/'/g, '"'));
  cohort_receivers = parameters.cohorts.split(",").length;

  const user_receivers = message.receivers ? message.receivers.length : 0;
  let result = `${translate(language, "PAGES.COHORT.MESSAGES.TO_LABEL")} `;

  if (cohort_receivers > 0 && user_receivers > 0) {
    result += `${cohort_receivers} ${translate(
      language,
      "PAGES.COHORT.MESSAGES.COHORTS_TAB_TITLE"
    )} & ${user_receivers} ${translate(language, "PAGES.COHORT.MESSAGES.USERS_TAB_TITLE")}`;
  } else if (cohort_receivers > 0) {
    result += `${cohort_receivers} ${translate(language, "PAGES.COHORT.MESSAGES.COHORTS_TAB_TITLE")}`;
  }
  return result;
};

export const listItemMessages = (language: string, messages: Array<any>) =>
  messages.map((message) => ({
    author_name: `${translate(language, "PAGES.COHORT.MESSAGES.FROM_LABEL")} ${message.author.full_name}`,
    name: _listReceivers(language, message),
    content: _truncateMessageContent(message.content),
    fullContent: message.content,
    date: message.dt_created,
    id: message.id,
  }));

export const fetchMessages = (cohort_id: string, setMessages: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohort_id}/messages`)
    .then((resp) => {
      setMessages(resp.data.messages);
      callback?.();
    })
    .catch((error) => {
      console.error("An error occurred with messages listing", error);
      setMessages([]);
      alert(translate(language, "PAGES.COHORT.MESSAGES.ERROR.FETCH_MESSAGES"));
    });
};

import React from "react";

const Chevron = ({
  color = "#9E9E9E",
  classes = "me-2",
  height = "1em",
  orientation = "right",
}: {
  color?: string;
  classes?: string;
  height?: string;
  orientation?: "bottom" | "top" | "left" | "right";
}) => {
  const _orientate = (orientation: string): string => {
    switch (orientation) {
      case "bottom":
        return "90deg";
      case "top":
        return "-90deg";
      case "left":
        return "180deg";
      case "right":
        return "0deg";
      default:
        return "0deg";
    }
  };

  return (
    <svg
      className={classes}
      height={height}
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${_orientate(orientation)})` }}
    >
      <path d="M1 1.2998L5.3501 5.6499L1 10" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default Chevron;

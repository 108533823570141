const Trash = ({ color = "#8F8FA8", classes = "me-2" }: { color?: string; classes?: string }) => (
  <svg className={classes} height="1em" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_849_19881)">
      <path d="M1.375 2.75H9.625" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.70866 2.75V9.16667C8.70866 9.625 8.25033 10.0833 7.79199 10.0833H3.20866C2.75033 10.0833 2.29199 9.625 2.29199 9.16667V2.75"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.66699 2.75033V1.83366C3.66699 1.37533 4.12533 0.916992 4.58366 0.916992H6.41699C6.87533 0.916992 7.33366 1.37533 7.33366 1.83366V2.75033"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.58301 5.04199V7.79199" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.41699 5.04199V7.79199" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_849_19881">
        <rect width="11" height="11" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Trash;

const ExternalLink = ({ color = "#393939", classes = "me-2" }: { color?: string; classes?: string }) => {
  return (
    <svg className={classes} height="1em" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.75 9.20833V13.4583C12.75 13.8341 12.6007 14.1944 12.3351 14.4601C12.0694 14.7257 11.7091 14.875 11.3333 14.875H3.54167C3.16594 14.875 2.80561 14.7257 2.53993 14.4601C2.27426 14.1944 2.125 13.8341 2.125 13.4583V5.66667C2.125 5.29094 2.27426 4.93061 2.53993 4.66493C2.80561 4.39926 3.16594 4.25 3.54167 4.25H7.79167"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.625 2.125H14.875V6.375"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08337 9.91667L14.875 2.125"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExternalLink;

const LogIn = ({ color = "white", classes = "me-2" }: { color?: string; classes?: string }) => (
  <svg className={classes} height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.48242 1.75H11.8158C12.1252 1.75 12.4219 1.87292 12.6407 2.09171C12.8595 2.3105 12.9824 2.60725 12.9824 2.91667V11.0833C12.9824 11.3928 12.8595 11.6895 12.6407 11.9083C12.4219 12.1271 12.1252 12.25 11.8158 12.25H9.48242"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.56543 9.91683L9.4821 7.00016L6.56543 4.0835"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.48242 7H2.48242" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LogIn;

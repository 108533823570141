import React, { useEffect, useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import DstTabsVertical from "../../../../../components/DstTabsVertical/DstTabsVertical";
import Popup from "../../../../../components/Popup/Popup";
import { formatToISODateTime } from "../../../../../services/TimeService";
import translate from "../../../../../services/Translate";
import { cohortSprintModulesType } from "../CohortSprint.d";
import { buildPopupTabs } from "./CohortSprintPopup.function";

export const handleAddNewSprint = (
  setSprintNames: Function,
  setInputValues: Function,
  setStartDates: Function,
  setEndDates: Function,
  setOptionalStatus: Function
) => {
  setSprintNames((sprintNames: string[]) => [...sprintNames, `Sprint ${sprintNames.length + 1}`]);
  setInputValues((inputValues: string[]) => [...inputValues, `Sprint ${inputValues.length + 1}`]);
  setStartDates((startDates: string[]) => [...startDates, ""]);
  setEndDates((endDates: string[]) => [...endDates, ""]);
  setOptionalStatus((optionalStatus: any[]) => [...optionalStatus, []]);
};

const CohortSprintPopup = ({
  language,
  showPopup,
  setShowPopup,
  activeKey,
  setActiveKey,
  sprintsAndModules,
  loadingModules,
  modules,
  handleSaveSprints,
}: {
  language: string;
  showPopup: boolean;
  setShowPopup: Function;
  activeKey: string;
  setActiveKey: Function;
  sprintsAndModules: cohortSprintModulesType;
  loadingModules: boolean;
  modules: any[];
  handleSaveSprints: Function;
}) => {
  const [isSending, setIsSending] = useState(false);
  const [sprintNames, setSprintNames] = useState<string[]>([]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [startDates, setStartDates] = useState<string[]>([]);
  const [endDates, setEndDates] = useState<string[]>([]);
  const [optionalStatus, setOptionalStatus] = useState<any[]>([]);

  useEffect(() => {
    if (Object.keys(sprintsAndModules).length > 0) {
      setSprintNames(Object.keys(sprintsAndModules));
      setInputValues(Object.keys(sprintsAndModules));
      setStartDates(
        Object.keys(sprintsAndModules).map((name) => formatToISODateTime(sprintsAndModules[name][0].dt_start))
      );
      setEndDates(Object.keys(sprintsAndModules).map((name) => formatToISODateTime(sprintsAndModules[name][0].dt_end)));
      setOptionalStatus(
        Object.keys(sprintsAndModules).map((name) =>
          sprintsAndModules[name].map((lesson) => ({
            id: lesson.id,
            name: lesson.moduleName,
            isOptional: !lesson.isMandatory,
          }))
        )
      );
    }
  }, [sprintsAndModules]);

  return (
    <Popup
      show={showPopup}
      onClose={() => setShowPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.SPRINT.POPUP.TITLE")}
      removePadding
      size="xl"
    >
      <DstTabsVertical
        titleIcon={<React.Fragment></React.Fragment>}
        classes="pt-0"
        bodyClass="lightgray-background"
        activeKey={activeKey}
        onSelect={(key: any) => key && setActiveKey(key)}
        fullHeight
        tabsData={buildPopupTabs(
          language,
          sprintNames,
          sprintsAndModules,
          isSending,
          setIsSending,
          inputValues,
          setInputValues,
          optionalStatus,
          setOptionalStatus,
          startDates,
          setStartDates,
          endDates,
          setEndDates,
          loadingModules,
          modules,
          (index: number) => {
            handleSaveSprints(
              index,
              inputValues,
              sprintNames,
              startDates,
              endDates,
              optionalStatus,
              sprintsAndModules,
              setSprintNames,
              language,
              () => {
                setIsSending(false);
                setShowPopup(false);
              }
            );
          }
        )}
        variant="light"
        TabsSize={{ xl: 3, lg: 4, xs: 3 }}
        customButtonComponent={
          <DstButton
            value={translate(language, "PAGES.COHORT.SPRINT.POPUP.ADD_SPRINT")}
            variant="light"
            backgroundColor="#F2F2F2"
            color="black"
            btnClass="w-fit my-3 custom-btn-role m-3"
            btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
            clickFunction={() =>
              handleAddNewSprint(setSprintNames, setInputValues, setStartDates, setEndDates, setOptionalStatus)
            }
          />
        }
      />
    </Popup>
  );
};

export default CohortSprintPopup;

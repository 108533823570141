import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { LoadedPermissionsData } from "./Permissions.d";

export const openRolePopup = (setShowPopups: Function, role: string) => {
  setShowPopups({ role });
};

export const closeRolePopup = (setShowPopups: Function) => {
  setShowPopups(null);
};

export const openRoutePopup = (setShowPopups: Function, route: string) => {
  setShowPopups({ route });
};

export const closeRoutePopup = (setShowPopups: Function) => {
  setShowPopups(null);
};

export const getPermissionsWithGodMode = (permissions: { [route: string]: any }) => ({
  "/**": [
    {
      documentation: {
        description: "This permissions allows you to do everything. God mode, litterally.",
        title: "God mode",
      },
      method: "*",
    },
  ],
  ...permissions,
});

export const createNewRole = async (language: string, fetchData: Function) => {
  const roleName = prompt(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.PROMPTS.NEW_ROLE_NAME"));
  const roleDescription = prompt(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.PROMPTS.NEW_ROLE_DESCRIPTION"));

  if (!roleName || !roleDescription) {
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_CREATION_MISSING_FIELD"));
    return;
  }

  const newRoleData = {
    name: roleName,
    description: roleDescription,
    routes: {
      "/auth/login": ["POST"],
    },
  };

  try {
    await ApiService.post("roles", newRoleData);
    await ApiService.post("roles/permissions/reload", {});
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_CREATED_SUCCEED"));
    fetchData();
  } catch (err) {
    console.error("Error creating new role", err);
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_CREATED_FAILED"));
  }
};

export const updateRole = async (
  language: string,
  roleId: string,
  rolePermissions: Array<any>,
  fetchData: Function
) => {
  const routes: { [key: string]: Array<string> } = {};
  rolePermissions.forEach((rolePermission: string) => {
    const delimiterPosition = rolePermission.indexOf("_");
    const method = rolePermission.slice(0, delimiterPosition);
    const endpoint = rolePermission.slice(delimiterPosition + 1);
    routes[endpoint] = routes[endpoint] ? [...routes[endpoint], method] : [method];
  });

  try {
    await ApiService.patch(`roles/${roleId}`, {
      routes,
    });
    await ApiService.post("roles/permissions/reload", {});
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_PATCHED_SUCCEED"));
    fetchData();
  } catch (err) {
    console.error("Error updating role", err);
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_PATCHED_FAILED"));
  }
};

export const handleDeleteRole = async (language: string, roleId: string, fetchData: Function) => {
  try {
    await ApiService.delete(`roles/${roleId}`);
    await ApiService.post("roles/permissions/reload", {});
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_DELETED_SUCCEED"));
    fetchData();
  } catch (err) {
    console.error("Error deleting role", err);
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_DELETED_FAILED"));
  }
};

export const duplicateRole = async (language: string, role: any, fetchData: Function) => {
  const roleName = prompt(
    translate(language, "PAGES.PERMISSIONS.FUNCTIONS.PROMPTS.DUPLICATE_ROLE_NAME"),
    `${role.name} Copy`
  );
  const roleDescription = prompt(
    translate(language, "PAGES.PERMISSIONS.FUNCTIONS.PROMPTS.DUPLICATE_ROLE_DESCRIPTION"),
    role.description
  );

  if (!roleName || !roleDescription) {
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_DUPLICATION_MISSING_FIELD"));
    return;
  }

  try {
    await ApiService.post("roles", {
      name: roleName,
      description: roleDescription,
      routes: role.routes,
    });
    await ApiService.post("roles/permissions/reload", {});
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_DUPLICATED_SUCCEED"));
    fetchData();
  } catch (err) {
    console.error("Error duplicating role", err);
    alert(translate(language, "PAGES.PERMISSIONS.FUNCTIONS.ALERTS.ROLE_DUPLICATED_FAILED"));
  }
};

export const fetchPermissionsData = (
  setRoles: Function,
  setRoleUsers: Function,
  setRolePermissions: Function,
  setPermissions: Function,
  setTags: Function,
  setLoaded: Function,
  setActiveTab: Function,
  activeTabRef: React.MutableRefObject<string>
) => {
  ApiService.get("roles?order=asc")
    .then((resp) => {
      const rolesData = resp.data.roles;
      setRoles(rolesData);

      rolesData.forEach((role: any) => {
        setRolePermissions((rolePermissions: any) => ({
          ...rolePermissions,
          [role.id]: Object.keys(role.routes)
            .map((endpoint: any) => role.routes[endpoint].map((method: any) => `${method}_${endpoint}`))
            .flat(),
        }));
        ApiService.get(`roles/${role.id}/users`)
          .then((response) => {
            setRoleUsers((prevRoleUsers: { [roleId: string]: number[] }) => ({
              ...prevRoleUsers,
              [role.id]: response.data.users,
            }));
          })
          .catch((error) => console.error(`An error occurred while fetching role ${role.id}`, error))
          .finally(() =>
            setLoaded((loaded: LoadedPermissionsData) => ({
              ...loaded,
              rolesUsers: { ...loaded.rolesUsers, [role.id]: true },
            }))
          );
      });

      if (rolesData.length > 0 && !activeTabRef.current) {
        setActiveTab(String(rolesData[0].id));
      }
    })
    .catch((err) => console.error("An error occurred while fetching roles", err))
    .finally(() => setLoaded((loaded: LoadedPermissionsData) => ({ ...loaded, roles: true })));

  ApiService.get("roles/permissions")
    .then((resp) => setPermissions(resp.data.permissions))
    .catch((err) => console.error("An error occurred while fetching permissions", err))
    .finally(() => setLoaded((loaded: LoadedPermissionsData) => ({ ...loaded, permissions: true })));
  ApiService.get("roles/routes/tags")
    .then((resp) => setTags(resp.data.tags))
    .catch((err) => console.error("An error occurred while fetching tags", err))
    .finally(() => setLoaded((loaded: LoadedPermissionsData) => ({ ...loaded, tags: true })));
};

export const handleSelectAllPermissions = (
  method: string,
  rolePermissions: { [roleId: string]: string[] },
  activeTab: string,
  permissions: { [route: string]: any },
  selectedTags: string[],
  setRolePermissions: Function
) => {
  const updatedRolePermissions = { ...rolePermissions };
  const activeRolePermissions = updatedRolePermissions[activeTab] || [];

  const routesFilteredByTags = Object.entries(permissions).filter(
    ([route, data]) =>
      selectedTags.length === 0 ||
      selectedTags.every((tag) =>
        data
          .map((dataMethod: any) => dataMethod?.documentation?.tags)
          .flat()
          .includes(tag)
      )
  );

  routesFilteredByTags.forEach(([route, methods]) => {
    const permissionKey = `${method}_${route}`;
    if (
      methods.map((routeMethod: any) => routeMethod.method).includes(method) &&
      !activeRolePermissions.includes(permissionKey)
    ) {
      activeRolePermissions.push(permissionKey);
    }
  });

  updatedRolePermissions[activeTab] = activeRolePermissions;
  setRolePermissions(updatedRolePermissions);
};

export const handleUnselectAllPermissions = (
  method: string,
  rolePermissions: { [roleId: string]: string[] },
  activeTab: string,
  permissions: { [route: string]: any },
  selectedTags: string[],
  setRolePermissions: Function
) => {
  const updatedRolePermissions = { ...rolePermissions };
  let activeRolePermissions = updatedRolePermissions[activeTab] || [];

  const routesFilteredByTags = Object.entries(permissions).filter(
    ([route, data]) =>
      selectedTags.length === 0 ||
      selectedTags.every((tag) =>
        data
          .map((dataMethod: any) => dataMethod?.documentation?.tags)
          .flat()
          .includes(tag)
      )
  );

  routesFilteredByTags.forEach(([route, methods]) => {
    const permissionKey = `${method}_${route}`;
    if (
      methods.map((routeMethod: any) => routeMethod.method).includes(method) &&
      activeRolePermissions.includes(permissionKey)
    ) {
      activeRolePermissions = activeRolePermissions.filter((perm_key) => perm_key !== permissionKey);
    }
  });

  updatedRolePermissions[activeTab] = activeRolePermissions;
  setRolePermissions(updatedRolePermissions);
};

import { matchPath } from "react-router";

import Schema from "../components/DstIcons/Schema";
import Users from "../components/DstIcons/Users";

const PATHS = [
  {
    paths: ["users", "/user/:user_id", "cohorts", "cohort/:cohort_id"],
    items: [
      { label: "Users", to: "/users", Icon: Users, iconProps: { classes: "" } },
      { label: "Cohorts", to: "/cohorts", Icon: Schema, iconProps: { classes: "" } },
    ],
  },
];

const provideLeftNavbarItems = (path: string) => {
  const matching_group = PATHS.map((group) => {
    if (group.paths.some((key) => matchPath(key, path))) return group;
    return null;
  }).filter((e) => e)?.[0];

  if (matching_group) {
    return matching_group.items;
  }
  return [];
};

export default provideLeftNavbarItems;

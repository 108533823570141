import React from "react";
import { Col, Row } from "react-bootstrap";

import { useSession } from "../../contexts/SessionContext";
import { NotesListProps } from "../../models/Note";
import { getAgeHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import Trash from "../DstIcons/Trash";
import "./NoteList.scss";

const _htmlToText = (html: string) => {
  const spacedHtml = html.replace(/<\/[^>]+>/g, " ");
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = spacedHtml;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const _getPreviewContent = (content: string) => {
  const plainText = _htmlToText(content);
  return plainText.length > 20 ? `${plainText.substring(0, 20)}...` : plainText;
};

const NotesList = ({ notes, selectNote, activeNote, deleteNote }: NotesListProps) => {
  const { language } = useSession();

  return (
    <div>
      {notes.map((note) => (
        <div
          key={note.id}
          onClick={() => selectNote(note)}
          className={`user-select-none p-1 ${activeNote && note.id === activeNote.id ? "note-active" : ""}`}
        >
          <div className="s-bold gray-text-font">
            <div className="d-flex justify-content-between">
              {note.title || (
                <span className="lightgray-font">{translate(language, "PAGES.USER.NOTE.EMPTY_TITLE")}</span>
              )}
              {activeNote && note.id === activeNote.id && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (window.confirm(translate(language, "PAGES.USER.NOTE.DELETE_MESSAGE"))) {
                      deleteNote(note.id);
                    }
                  }}
                  className="clickable"
                >
                  <Trash color="#D3C7A6" />
                </div>
              )}
            </div>
          </div>
          <Row className="xs-regular note-preview">
            <Col lg={5}>
              {getAgeHumanly(language, note.dt_created)}
              <br />
              {translate(language, "PAGES.USER.NOTE.BY")}{" "}
              {note.author_name || translate(language, "PAGES.USER.NOTE.UNKNOWN_USER")}
            </Col>
            <Col lg={7} className="lightgray-font text-note">
              {_getPreviewContent(note.value)}
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
export default NotesList;

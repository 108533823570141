const CheckIconRounded = ({
  color = "#F2EFFF",
  bgColor = "#56CEB2",
  classes = "me-2",
  height = "1em",
}: {
  color?: string;
  bgColor?: string;
  classes?: string;
  height?: string;
}) => {
  return (
    <svg className={classes} height={height} viewBox="-1 -1 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="10" height="10" rx="5" fill={bgColor} />
      <path d="M8 3L3.94118 7" stroke={color} strokeWidth="1.13636" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M2.25 5.3335L3.94118 7.00016"
        stroke={color}
        strokeWidth="1.13636"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIconRounded;

const getStatusColor = (status: string): string => {
  let statusColor = "";

  switch (status.toLowerCase()) {
    case "failed":
    case "repass":
      statusColor = "text-danger";
      break;
    case "success":
      statusColor = "text-success";
      break;
    case "correction_pending":
    case "pre_corrected":
      statusColor = "text-warning";
      break;
    case "being_corrected":
    case "pre_correction_in_progress":
      statusColor = "text-primary";
      break;
    case "created":
    case "in_progress":
      statusColor = "text-secondary";
      break;
    default:
      statusColor = "";
  }

  return statusColor;
};

export default getStatusColor;

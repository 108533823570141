import React, { useState } from "react";
import { Col, Dropdown, OverlayTrigger, Placeholder, ProgressBar, Row, Tooltip } from "react-bootstrap";
import ReactCountryFlag from "react-country-flag";
import ReactQuill from "react-quill";
import { useNavigate, useParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import { useSession } from "../../contexts/SessionContext";
import TimeService from "../../services/TimeService";
import translate from "../../services/Translate";
import evaluations, { userMocks } from "./Correction.mock";
import "./Correction.scss";
import ExamStatusDropdown from "./components/ExamStatusDropdown";

const Correction = () => {
  const { language } = useSession();
  const { eval_id } = useParams();
  const navigate = useNavigate();

  const [showOverlay, setShowOverlay] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isCorrectionStarted, setIsCorrectionStarted] = useState(false);

  const handleShowOverlay = () => {
    setShowOverlay(true);
    setIsCorrectionStarted(true);
  };

  const evaluation = evaluations.find((evaluationItem) => evaluationItem.eval_id === Number(eval_id));

  if (!evaluation) {
    return (
      <React.Fragment>
        <div className="border-bottom">
          <div className="d-flex align-items-center my-3 mx-2 justify-content-between">
            <Placeholder as="h2" animation="glow" className="w-100">
              <Placeholder xs={8} />
            </Placeholder>
            <div className="d-flex">
              <div className="users-show-eval d-flex me-2">
                {[...Array(3)].map((_, index) => (
                  <Placeholder key={index} as="div" animation="glow" className="me-2">
                    <Placeholder.Button className="rounded-circle object-fit-cover clickable size-img-picture" />
                  </Placeholder>
                ))}
              </div>
              <div className="users-show-eval d-flex me-2">
                {[...Array(2)].map((_, index) => (
                  <Placeholder key={index} as="div" animation="glow" className="me-2">
                    <Placeholder.Button className="rounded-circle object-fit-cover clickable size-img-picture" />
                  </Placeholder>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Row className="mx-0">
          <Col md lg={8} className="lightgray-background p-2 scrollable" id="content-exam">
            <div>
              <Placeholder as="div" animation="glow" className="white-background p-2">
                <Placeholder xs={12} style={{ height: "calc(100vh - (96.5px + 31px + 3rem))" }} />
              </Placeholder>
            </div>
          </Col>
          <Col md lg={4} className="p-3 s-regular">
            <div id="eval-info" className="overflow-auto mb-2">
              <Placeholder as="h3" animation="glow">
                <Placeholder xs={4} />
              </Placeholder>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Row className="mx-0">
                  <Col className="ps-0">
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </Col>
                  <Col className="pe-0">
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="div" animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  </Col>
                </Row>
              </div>
              <div className="mt-2">
                <Placeholder as="h3" animation="glow">
                  <Placeholder xs={12} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={5} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={3} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={2} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <div className="d-flex border-bottom pb-2 align-items-center">
                  <div className="d-flex">
                    {[...Array(4)].map((_, index) => (
                      <Placeholder
                        key={index}
                        as="span"
                        animation="glow"
                        className="me-1 rounded"
                        style={{ width: "20px", height: "20px" }}
                      >
                        <Placeholder xs={12} />
                      </Placeholder>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={10} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </div>
              <div className="mt-2">
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={4} />
                </Placeholder>
                <Placeholder as="div" animation="glow">
                  <Placeholder xs={8} />
                </Placeholder>
              </div>
            </div>
            <div className="d-grid">
              <DstButton mock />
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }

  const parseTimeString = (timeString: string): number => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return (
      (hours * TimeService.HOURS_IN_MILLISECONDS) / 1000 +
      (minutes * TimeService.MINUTES_IN_MILLISECONDS) / 1000 +
      seconds
    );
  };

  const durationSeconds = parseTimeString(evaluation.duration);
  const totalDurationSeconds = parseTimeString(evaluation.total_duration);

  const progressValue = totalDurationSeconds ? durationSeconds / totalDurationSeconds : 0;
  const additionalUsers = userMocks.length - 3;

  return (
    <React.Fragment>
      <div className="border-bottom">
        <div className="d-flex align-items-center my-3 mx-2 justify-content-between">
          <div className="d-flex">
            <i
              className="dst-icon-arrow-circle-left h2 me-2 mb-0 blue-font clickable"
              onClick={() => navigate("/evaluation-attempts/pending")}
              role="button"
              aria-label="Go to Evaluations pending for correction"
            />
            <h2 className="mb-0">{evaluation.nom_de_l_eval}</h2>
          </div>
          <div className="d-flex">
            <div className="d-flex users-show-eval">
              {userMocks.slice(0, 3).map((user) => (
                <OverlayTrigger
                  key={user.id}
                  placement="top"
                  overlay={<Tooltip id={`tooltip-${user.id}`}>{user.name}</Tooltip>}
                >
                  <img
                    src={user.imageUrl}
                    alt={`${user.name}'s profile`}
                    className="rounded-circle object-fit-cover me-2 clickable size-img-picture"
                    onClick={() => navigate(`/user/${user.id}`)}
                  />
                </OverlayTrigger>
              ))}
              {additionalUsers > 0 && (
                <Dropdown align="end" id="additional-users">
                  <Dropdown.Toggle
                    as="div"
                    className="rounded-circle bg-light d-flex align-items-center justify-content-center me-3 clickable size-img-picture"
                  >
                    <span className="s-regular">+{additionalUsers}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {userMocks.slice(3).map((user) => (
                      <Dropdown.Item
                        key={user.id}
                        onClick={() => navigate(`/user/${user.id}`)}
                        className="d-flex align-items-center xs-regular"
                      >
                        <img
                          src={user.imageUrl}
                          alt={`${user.name}'s profile`}
                          className="rounded-circle object-fit-cover me-2 size-img-picture"
                        />
                        <span>{user.name}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <div className="mt-1">
              <i
                className="dst-icon-key-01 gray-background p-2 rounded-circle clickable m-regular me-2"
                onClick={() => alert("WIP")}
              />
            </div>
            <div className="mt-1">
              <i
                className="dst-icon-download-02 gray-background p-2 rounded-circle clickable m-regular"
                onClick={() => alert("WIP")}
              />
            </div>
          </div>
        </div>
      </div>
      <Row className="mx-0">
        <Col md lg={8} className="lightgray-background p-2 scrollable" id="content-exam">
          <div dangerouslySetInnerHTML={{ __html: evaluation.contenu_de_l_eval }} className="white-background p-2" />
        </Col>
        <Col md lg={4} className="s-regular d-flex flex-column px-0 justify-content-between" id="eval-info">
          <div className="overflow-auto mb-2 p-3">
            <h3> {translate(language, "PAGES.EVAL.USER_INFO.TITLE")}</h3>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.USER_INFO.LEARNER_NAME")}</div>
              <div className="d-flex justify-content-between border-bottom pb-2">
                <div>
                  <i className="dst-icon-users-01 me-2" />
                  {evaluation.learner_name}
                  <span
                    className="clickable lightgray-font ms-2"
                    onClick={() => navigate(`/user/${evaluation.learner_id}`)}
                  >
                    #{evaluation.learner_id}
                  </span>
                </div>
                <div>
                  <i className="blue-font dst-icon-trophy-01 me-1"></i>
                  <i className="blue-font dst-icon-star-01"></i>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.USER_INFO.COHORT_NAME")}</div>
              <div className="d-flex border-bottom pb-2">
                <div>
                  <i className="dst-icon-users-01 me-2" />
                  {evaluation.cohort_name}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <Row className="mx-0">
                <Col className="ps-0">
                  <div className="darkgray-font">{translate(language, "PAGES.EVAL.USER_INFO.PROGRAM_MANAGER")}</div>
                  <div className="d-flex border-bottom pb-2">
                    <div>
                      <i className="dst-icon-users-01 me-2" />
                      {evaluation.program_manager}
                    </div>
                  </div>
                </Col>
                <Col className="pe-0">
                  <div className="darkgray-font">{translate(language, "PAGES.EVAL.USER_INFO.COHORT_LEADER")} </div>
                  <div className="d-flex border-bottom pb-2">
                    <div>
                      <i className="dst-icon-users-01 me-2" />
                      {evaluation.cohort_leader}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="mt-2 d-flex justify-content-between align-items-center">
              <h3 className="mb-0">{translate(language, "PAGES.EVAL.EXAM_INFO.TITLE")}</h3>
              <i className="dst-icon-flag-05 me-1 gray-background p-2 rounded-circle "></i>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_STATUS")}</div>
              <div className="d-flex border-bottom pb-2">
                <div>
                  <i className="dst-icon-hourglass-01 me-2" />
                  {evaluation.exam_status}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.AI_PREVISION_SCORE")}</div>
              <div className="d-flex border-bottom pb-2 orange-font">
                <div>
                  <i className="dst-icon-atom-02 me-2" />
                  {evaluation.ai_prevision_score}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.NUMBER_ATTEMPTS")}</div>
              <div className="d-flex border-bottom pb-2 align-items-center">
                <i className="dst-icon-file-check-03 me-2" />

                {[...Array(3)].map((_, attemptIndex) => {
                  const attempt = attemptIndex + 1;
                  let backgroundColorClass = "bg-light";

                  if (attempt === evaluation.number_of_attempts) {
                    switch (attempt) {
                      case 1:
                        backgroundColorClass = "medium-green-background text-white";
                        break;
                      case 2:
                        backgroundColorClass = "orange-background text-white";
                        break;
                      case 3:
                        backgroundColorClass = "red-background text-white";
                        break;
                      default:
                        backgroundColorClass = "bg-light";
                    }
                  }

                  return (
                    <span key={attempt} className={`me-1 px-2 rounded ${backgroundColorClass}`}>
                      {attempt}
                    </span>
                  );
                })}

                {evaluation.number_of_attempts > 3 && (
                  <span className="px-2 rounded electricblue-background text-white">
                    {evaluation.number_of_attempts}
                  </span>
                )}
              </div>
            </div>

            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.DURATION")}</div>
              <div className="d-flex border-bottom pb-2">
                <div>
                  <div className="d-flex align-items-center">
                    <i className="dst-icon-clock-stopwatch me-2" />
                    <span className="me-2">
                      {evaluation.duration}' / {evaluation.total_duration}
                    </span>
                    <ProgressBar
                      id="duration-eval"
                      className="lightblue-background"
                      now={progressValue * 100}
                      label={`${(progressValue * 100).toFixed(1)}%`}
                      style={{
                        width: "150px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.TITLE")}</div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-mail-02 me-2" />
                  {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.SENDING_DATE")}
                  <span className="darkgray-font ms-3">{evaluation.exam_mail_send_date}</span>
                </div>
              </div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-mail-04 me-2" />
                  {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.OPENING_DATE")}
                  <span className="darkgray-font ms-3">{evaluation.exam_mail_opening_date}</span>
                </div>
              </div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-play me-2" />
                  {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.START_DATE")}
                  <span className="darkgray-font ms-3">{evaluation.exam_start_datetime}</span>
                </div>
              </div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-check me-2" />
                  {translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.END_DATE")}
                  <span className="darkgray-font ms-3">{evaluation.exam_end_datetime}</span>
                </div>
              </div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-fast-backward me-2" />
                  <a href="https://google.com/">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_TIMELINE.BACKUPS")}</a>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <div className="darkgray-font">{translate(language, "PAGES.EVAL.EXAM_INFO.EXAM_NAME")}</div>
              <div className="d-flex border-bottom py-2">
                <div>
                  <i className="dst-icon-file-01 me-2" />
                  <span className="ms-3">{evaluation.exam_name}</span>
                </div>
              </div>
            </div>
          </div>
          {!isCorrectionStarted && (
            <div className="d-grid px-3">
              <DstButton
                value={translate(language, "PAGES.EVAL.START_CORRECTION")}
                btnImageBefore={<i className="dst-icon-play me-2" />}
                clickFunction={handleShowOverlay}
              />
            </div>
          )}
          {showOverlay && (
            <div id="overlay-exam" className="white-background p-3 border">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="mb-0">{translate(language, "PAGES.EVAL.REVIEW.TITLE")}</h3>
                <i
                  className={`clickable p-2 light-background rounded-circle ${
                    isMinimized ? "dst-icon-chevron-up" : "dst-icon-chevron-down"
                  }`}
                  onClick={() => setIsMinimized(!isMinimized)}
                  aria-label="Minimize"
                />
              </div>
              {!isMinimized && (
                <div className="mt-2">
                  <div id="editor-feedback-exam">
                    <ReactQuill
                      value={editorContent}
                      onChange={setEditorContent}
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "blockquote"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          ["code-block"],
                        ],
                      }}
                      placeholder={translate(language, "PAGES.EVAL.REVIEW.PLACEHOLDER")}
                    />
                  </div>
                  <div className="p-1 rounded light-orange-background mt-2 xs-regular">
                    <i className="dst-icon-lightbulb-02 me-2" />
                    {translate(language, "PAGES.EVAL.REVIEW.REMEMBER_LANGUAGE").replace(
                      "{{LANGUAGE}}",
                      evaluation.learner_lang
                    )}{" "}
                    <ReactCountryFlag
                      svg
                      countryCode={evaluation.learner_lang.split("-")[1]}
                      title={evaluation.learner_lang}
                      className="pb-1 h-auto"
                    />
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <ExamStatusDropdown />
                    <DstButton
                      btnClass="mt-2"
                      value={translate(language, "PAGES.EVAL.BUTTON_SEND")}
                      btnSize="lg"
                      btnImageBefore={<i className="dst-icon-send-03 me-1" />}
                      clickFunction={() => alert("WIP!")}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Correction;

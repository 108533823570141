import React from "react";

const ThreeDots = ({
  color = "#393939",
  classes = "me-2",
  height = "0.33em",
  orientation = "vertical",
}: {
  color?: string;
  classes?: string;
  height?: string;
  orientation?: string;
}) => {
  return (
    <svg
      className={classes}
      height={height}
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${orientation === "horizontal" ? "0deg" : "90deg"})`,
      }}
    >
      <circle cx="2" cy="2" r="2" fill={color} />
      <circle cx="8" cy="2" r="2" fill={color} />
      <circle cx="14" cy="2" r="2" fill={color} />
    </svg>
  );
};

export default ThreeDots;

import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Form, Modal, Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../../../../components/Popup/Popup";
import translate from "../../../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../../../services/UtilityService";
import {
  Animator,
  CohortMeetingsProps,
  FormData,
  LanguageKeys,
  Organizer,
  Program,
  VisibilityKeys,
} from "./CohortMeetings.d";
import { createMeeting, getAdmins, getMeetings } from "./CohortMeetings.function";
import "./CohortMeetings.scss";

const CohortMeetings = ({ cohortId, language }: CohortMeetingsProps) => {
  const navigate = useNavigate();
  const [animators, setAnimators] = useState<Animator[]>([]);
  const [duration, setDuration] = useState(120);
  const [durationError, setDurationError] = useState(false);
  const [meetings, setMeetings] = useState<any[]>([]);
  const [generateZoomLink, setGenerateZoomLink] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [organizers, setOrganizers] = useState<Organizer[]>([]);
  const [programs, setPrograms] = useState<Program[]>([]);
  const [selectedAnimators, setSelectedAnimators] = useState<Animator[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageKeys>("FR");
  const [selectedMeetingType] = useState("masterclass");
  const [selectedOrganizers, setSelectedOrganizers] = useState<Organizer[]>([]);
  const [selectedProgram, setSelectedProgram] = useState("");
  const [selectedVisibility, setSelectedVisibility] = useState<VisibilityKeys>("datascientest");
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const columns = [
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.NAME"), key: "meeting_name" },
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.START_DATE"), key: "start.display" },
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.END_DATE"), key: "end.display" },
    {
      name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.TYPE"),
      key: "meeting_type_name",
      render: (item: any) => item.meeting_type.meeting_type_name,
    },
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.ATTENDANCE_RECORD"), key: "attendance_record" },
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.VISIBILITY"), key: "visibility" },
    { name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.ORGANIZER"), key: "organizer_id" },
    {
      name: translate(language, "PAGES.COHORT.MEETINGS.FIELDS.ANIMATORS"),
      key: "animators",
      render: (item: any) =>
        item.animators ? item.animators.map((animator: Animator) => animator.username).join(", ") : "",
    },
  ];
  const preProcessedData = meetings?.map((item: any) => ({ ...item, columns }));

  const languageMapping: Record<LanguageKeys, string> = {
    FR: translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.LANGUAGE.FRENCH"),
    EN: translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.LANGUAGE.ENGLISH"),
  };

  const visibilityOptions: Record<VisibilityKeys, string> = {
    datascientest: translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.VISIBILITY.DATASCIENTEST"),
    private: translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.VISIBILITY.PRIVATE"),
  };

  useEffect(() => {
    getAdmins(setOrganizers, setAnimators, setPrograms, language);
    getMeetings(cohortId, setMeetings, language, () => {
      setLoading(false);
    });
  }, [cohortId, language, setMeetings]);

  const resetSelection = () => {
    setDuration(120);
    setDurationError(false);
    setGenerateZoomLink(false);
    setSelectedAnimators([]);
    setSelectedLanguage("FR");
    setSelectedOrganizers([]);
    setSelectedProgram("");
    setSelectedVisibility("datascientest");
    setStartDate("");
    setTitle("");
  };

  const validateForm = useCallback(() => {
    const isValid =
      !!selectedProgram &&
      !!selectedLanguage &&
      !!title &&
      !!startDate &&
      duration > 0 &&
      !!selectedVisibility &&
      selectedOrganizers.length > 0 &&
      !durationError;
    setIsFormValid(isValid);
  }, [
    selectedProgram,
    selectedLanguage,
    title,
    startDate,
    duration,
    selectedVisibility,
    selectedOrganizers,
    durationError,
  ]);

  useEffect(() => {
    validateForm();
  }, [
    selectedProgram,
    selectedLanguage,
    title,
    startDate,
    duration,
    selectedVisibility,
    selectedOrganizers,
    durationError,
    validateForm,
  ]);

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    if (parsedValue > 0) {
      setDurationError(false);
      setDuration(parsedValue);
    } else {
      setDurationError(true);
      setDuration(0);
    }
  };

  const handleConfirm = () => {
    const formData: FormData = {
      selectedMeetingType,
      selectedProgram,
      selectedLanguage,
      title,
      startDate,
      duration,
      selectedVisibility,
      selectedOrganizers,
      selectedAnimators,
      generateZoomLink,
    };
    resetSelection();
    setShowModal(false);

    createMeeting(cohortId, formData, language, () => {
      setShowModal(false);
      setLoading(true);
      getMeetings(cohortId, setMeetings, language, () => {
        setLoading(false);
      });
    });
  };

  const handleRefresh = () => {
    setLoading(true);
    getMeetings(cohortId, setMeetings, language, () => {
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <div className="container-meetings-cohort px-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex">
            <h2 className="text-center me-3">{translate(language, "PAGES.COHORT.MEETINGS.TITLE")}</h2>
            <DstTableSearch data={preProcessedData} searchableColumns={["organizer_id"]} onSearch={setFilteredData} />
          </div>
          <div className="d-flex">
            <DstButton
              variant="light"
              value={translate(language, "PAGES.COHORT.MEETINGS.BUTTONS.REFRESH")}
              mock
              btnWidth="88px"
              btnImageBefore={<span className="dst-icon-refresh-cw-04 pe-2 align-text-bottom" />}
            />
            <DstButton
              btnClass="ms-2"
              value={translate(language, "PAGES.COHORT.MEETINGS.BUTTONS.ADD")}
              mock
              btnWidth="115px"
              btnImageBefore={<span className="dst-icon-plus pe-2 align-text-bottom" />}
            />
          </div>
        </div>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
          <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
          <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
        </Placeholder>
      </div>
    );
  }

  return (
    <div className="container-meetings-cohort px-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="text-center me-3">{translate(language, "PAGES.COHORT.MEETINGS.TITLE")}</h2>
          <DstTableSearch
            data={preProcessedData}
            searchableColumns={[
              "meeting_name",
              "start",
              "end",
              "meeting_type_name",
              "attendance_record",
              "visibility",
              "organizer_id",
              "animators",
            ]}
            onSearch={setFilteredData}
          />
        </div>
        <div className="d-flex">
          <DstButton
            variant="light"
            value={translate(language, "PAGES.COHORT.MEETINGS.BUTTONS.REFRESH")}
            btnImageBefore={<span className="dst-icon-refresh-cw-04 pe-2 align-text-bottom" />}
            clickFunction={handleRefresh}
          />
          <DstButton
            btnClass="ms-2"
            value={translate(language, "PAGES.COHORT.MEETINGS.BUTTONS.ADD")}
            btnImageBefore={<span className="dst-icon-plus pe-2 align-text-bottom" />}
            clickFunction={() => setShowModal(true)}
          />
        </div>
      </div>
      {meetings.length > 0 ? (
        <DstTable
          data={filteredData.length > 0 ? filteredData : preProcessedData}
          columns={columns}
          onClick={(event, item) =>
            completeNavigate(event, navigate, `${getLegacyAdminHostname()}/meetings/${item.meeting_id}`)
          }
        />
      ) : (
        <div className="mt-3 text-muted">{translate(language, "PAGES.COHORT.MEETINGS.NO_MEETINGS_FOUND")}</div>
      )}
      <Popup
        show={showModal}
        onClose={() => setShowModal(false)}
        modalTitle={translate(language, "PAGES.COHORT.MEETINGS.MODAL.TITLE")}
        scrollable={true}
      >
        <Form id="modal-add-meeting">
          <Form.Group>
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.MEETING_TYPE")}</Form.Label>
            <Form.Control className="border-secondary" type="text" readOnly value={selectedMeetingType} disabled />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.PROGRAM")}</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className={`w-100 d-flex justify-content-between align-items-center border-secondary ${
                  !selectedProgram ? "text-muted" : ""
                }`}
                variant=""
              >
                {selectedProgram || translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.PROGRAM")}
              </Dropdown.Toggle>
              <Dropdown.Menu className="scrollable max-height-30vh w-100">
                {programs.length > 0 &&
                  programs.map((program, index) => (
                    <Dropdown.Item key={index} onClick={() => setSelectedProgram(program)}>
                      {program}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.LANGUAGE")}</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex justify-content-between align-items-center border-secondary"
                variant=""
              >
                {languageMapping[selectedLanguage]}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {Object.keys(languageMapping).map((key) => (
                  <Dropdown.Item key={key} onClick={() => setSelectedLanguage(key as LanguageKeys)}>
                    {languageMapping[key as LanguageKeys]}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.TITLE_SELECT")}</Form.Label>
            <Form.Control
              className={`border-secondary ${!title ? "text-muted" : ""}`}
              type="text"
              placeholder={translate(language, "PAGES.COHORT.MEETINGS.MODAL.PLACEHOLDERS.TITLE_SELECT")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.START")}</Form.Label>
            <Form.Control
              className={`border-secondary ${!startDate ? "text-muted" : ""}`}
              type="datetime-local"
              value={startDate || ""}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.DURATION")}</Form.Label>
            <Form.Control
              className={`border-secondary ${durationError ? "border-danger" : ""}`}
              type="text"
              value={duration}
              onChange={handleDurationChange}
            />
            {durationError && (
              <Form.Text className="text-danger">
                {translate(language, "PAGES.COHORT.MEETINGS.ERRORS.UNFILLED_DURATION")}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.VISIBILITY")}</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex justify-content-between align-items-center border-secondary"
                variant=""
              >
                {visibilityOptions[selectedVisibility]}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                <Dropdown.Item onClick={() => setSelectedVisibility("datascientest")}>
                  {translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.VISIBILITY.DATASCIENTEST")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSelectedVisibility("private")}>
                  {translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.VISIBILITY.PRIVATE")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.ORGANIZER")}</Form.Label>
            <MultiSelectDropdown
              label={
                selectedOrganizers.length > 0
                  ? selectedOrganizers.map((item) => item.username).join(", ")
                  : translate(language, "PAGES.COHORT.MEETINGS.MODAL.PLACEHOLDERS.ORGANIZER")
              }
              badgeItemProperty="username"
              displayItem={(item) => item.username}
              toggleClass={`text-truncate w-100 border-secondary ${
                selectedOrganizers.length === 0 ? "text-muted" : ""
              }`}
              onConfirm={(selectedItems) => {
                setSelectedOrganizers(selectedItems);
              }}
              items={organizers}
              language={language}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.ANIMATORS")}</Form.Label>
            <MultiSelectDropdown
              label={
                selectedAnimators.length > 0
                  ? `${selectedAnimators.length} ${translate(
                      language,
                      `PAGES.COHORT.MEETINGS.MODAL.PLACEHOLDERS.${
                        selectedAnimators.length === 1 ? "ANIMATOR_SELECTED" : "ANIMATORS_SELECTED"
                      }`
                    )}`
                  : translate(language, "PAGES.COHORT.MEETINGS.MODAL.PLACEHOLDERS.ANIMATORS")
              }
              badgeItemProperty="username"
              displayItem={(item) => item.username}
              toggleClass={`text-truncate w-100 border-secondary ${selectedAnimators.length === 0 ? "text-muted" : ""}`}
              onConfirm={(selectedItems) => {
                setSelectedAnimators(selectedItems);
              }}
              items={animators}
              language={language}
              showAlwaysConfirm={true}
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>{translate(language, "PAGES.COHORT.MEETINGS.MODAL.LABELS.GENERATE_ZOOM_LINK")}</Form.Label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex justify-content-between align-items-center border-secondary"
                variant=""
              >
                {generateZoomLink
                  ? translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.YES")
                  : translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.NO")}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                <Dropdown.Item onClick={() => setGenerateZoomLink(true)}>
                  {translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.YES")}
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setGenerateZoomLink(false)}>
                  {translate(language, "PAGES.COHORT.MEETINGS.MODAL.OPTIONS.ZOOM_LINK.NO")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </Form>
        <Modal.Footer className="pt-5 border-top-0">
          <DstButton
            variant="secondary"
            value={translate(language, "PAGES.COHORT.MEETINGS.MODAL.BUTTONS.CANCEL")}
            clickFunction={() => {
              setShowModal(false);
              resetSelection();
            }}
          />
          <DstButton
            value={translate(language, "PAGES.COHORT.MEETINGS.MODAL.BUTTONS.CONFIRM")}
            clickFunction={handleConfirm}
            disabled={!isFormValid}
          />
        </Modal.Footer>
      </Popup>
    </div>
  );
};

export default CohortMeetings;

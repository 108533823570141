import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InputGroup, Placeholder } from "react-bootstrap";

import DstButton from "../../components/DstButton/DstButton";
import DstTable from "../../components/DstTable/DstTable";
import DstTableSearch from "../../components/DstTable/DstTableSearch/DstTableSearch";
import MultiFilterDropdown from "../../components/MultiFilterDropdown/MultiFilterDropdown";
import Popup from "../../components/Popup/Popup";
import { useSession } from "../../contexts/SessionContext";
import { EvaluationAttempt, User } from "../../models/EvaluationAttempt";
import ApiService from "../../services/ApiService";
import { computeElapsedTime, computeRemainingTime } from "../../services/TimeService";
import translate from "../../services/Translate";
import getStatusColor from "./EvaluationAttempts.function";

const EvaluationAttempts = () => {
  const { language } = useSession();
  const [evaluationAttempts, setEvaluationAttempts] = useState<EvaluationAttempt[]>([]);
  const [filteredData, setFilteredData] = useState<EvaluationAttempt[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [selectedUserLanguages, setSelectedUserLanguages] = useState<string[]>([]);
  const [selectedItems, setSelectedItems] = useState<EvaluationAttempt[]>([]);
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState<string[]>([]);
  const [selectedIntensities, setSelectedIntensities] = useState<string[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  useEffect(() => {
    setLoading(true);
    ApiService.get("evaluations/attempts")
      .then((response) => {
        const attempts = response.data.evaluation_attempts_list;
        setEvaluationAttempts(attempts);
        setFilteredData(attempts);
      })
      .catch((err) => {
        console.error("Error fetching evaluations:", err);
        alert(translate(language, "PAGES.ATTEMPTS.ERROR"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language]);
  const programs = useMemo(
    () => Array.from(new Set(evaluationAttempts.map((attempt) => attempt.evaluation.program).filter(Boolean))),
    [evaluationAttempts]
  );
  const statuses = useMemo(
    () => Array.from(new Set(evaluationAttempts.map((attempt) => attempt.status).filter(Boolean))),
    [evaluationAttempts]
  );
  const userLanguages = useMemo(
    () => Array.from(new Set(evaluationAttempts.map((attempt) => attempt.user.language).filter(Boolean))),
    [evaluationAttempts]
  );
  const customerTypes = useMemo(
    () =>
      Array.from(
        new Set(
          evaluationAttempts.flatMap((attempt) => attempt.cohorts.map((cohort) => cohort.customer_type)).filter(Boolean)
        )
      ),
    [evaluationAttempts]
  );
  const intensities = useMemo(
    () =>
      Array.from(
        new Set(
          evaluationAttempts.flatMap((attempt) => attempt.cohorts.map((cohort) => cohort.intensity)).filter(Boolean)
        )
      ),
    [evaluationAttempts]
  );
  const searchableColumns = useMemo(() => ["evaluation.name", "user.full_name"], []);

  const handleShowPopup = (user: User) => {
    setSelectedUser(user);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedUser(null);
  };

  useEffect(() => {
    const filtered = evaluationAttempts.filter(
      (attempt) =>
        (selectedPrograms.length === 0 || selectedPrograms.includes(attempt.evaluation.program)) &&
        (selectedStatuses.length === 0 || selectedStatuses.includes(attempt.status)) &&
        (selectedUserLanguages.length === 0 || selectedUserLanguages.includes(attempt.user.language)) &&
        (selectedCustomerTypes.length === 0 ||
          attempt.cohorts.some((cohort) => selectedCustomerTypes.includes(cohort.customer_type))) &&
        (selectedIntensities.length === 0 ||
          attempt.cohorts.some((cohort) => selectedIntensities.includes(cohort.intensity)))
    );
    setFilteredData(filtered);
  }, [
    selectedPrograms,
    selectedStatuses,
    selectedUserLanguages,
    selectedCustomerTypes,
    selectedIntensities,
    evaluationAttempts,
  ]);
  const handleSearch = useCallback((filteredResults: EvaluationAttempt[]) => {
    setFilteredData(filteredResults);
  }, []);
  if (loading) {
    return (
      <React.Fragment>
        <div>
          <Placeholder as={"h1"} animation="glow">
            <div className="p-3">
              <Placeholder xs={4} role="mockup" />
            </div>
          </Placeholder>
          <hr />
          <div className="px-3">
            <div className="d-flex mb-3 justify-content-between">
              <div className="d-flex">
                <div className="me-2">
                  <MultiFilterDropdown mock inputTitle={""} language={""} onSelectTags={() => {}} tags={[]} />
                </div>
                <div className="me-2">
                  <MultiFilterDropdown mock inputTitle={""} language={""} onSelectTags={() => {}} tags={[]} />
                </div>
                <div className="me-2">
                  <MultiFilterDropdown mock inputTitle={""} language={""} onSelectTags={() => {}} tags={[]} />
                </div>
                <div className="me-2">
                  <MultiFilterDropdown mock inputTitle={""} language={""} onSelectTags={() => {}} tags={[]} />
                </div>
                <div className="me-2">
                  <MultiFilterDropdown mock inputTitle={""} language={""} onSelectTags={() => {}} tags={[]} />
                </div>
              </div>
              <DstButton mock btnWidth="195px" />
            </div>
            <Placeholder as={"div"} animation="glow">
              <Placeholder xs={2} role="mockup" style={{ height: "38px" }} />
            </Placeholder>{" "}
            <div className="d-flex align-items-center justify-content-end xs-regular">
              <Placeholder as={"div"} animation="glow">
                <Placeholder xs={4} role="mockup" />
              </Placeholder>{" "}
              <DstButton mock btnWidth="140px" btnClass="me-2" />
              <DstButton mock btnWidth="125px" btnClass="me-2" />
              <DstButton mock btnWidth="180px" />
            </div>
            <Placeholder as={"p"} animation="glow">
              <Placeholder xs={6} role="mockup" />
            </Placeholder>{" "}
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
              <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
              <Placeholder xs={12} className="w-100 mb-3" style={{ height: "50px" }} />
            </Placeholder>
          </div>
        </div>
      </React.Fragment>
    );
  }
  const columns = [
    {
      key: "evaluation.name",
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.EXAM_NAME"),
      render: (item: EvaluationAttempt) => {
        let iconClass = "dst-icon-message-question-square";
        switch (item.evaluation.type.toLowerCase()) {
          case "notebook":
            iconClass = "dst-icon-file-code-01";
            break;
          case "upload":
            iconClass = "dst-icon-paperclip";
            break;
          case "html":
            iconClass = "dst-icon-code-02";
            break;
        }
        return (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i className={`${iconClass} me-2`} />
              {item.evaluation.name || "N/A"}{" "}
            </div>
            <i className="clickable dst-icon-dots-horizontal" onClick={() => alert("WIP")} />
          </div>
        );
      },
    },
    {
      key: "deadline",
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.TITLE"),
      render: (item: EvaluationAttempt) => {
        let deadlineText = "";
        let deadlineClass = "";

        if (item.status.toLowerCase() === "correction_pending" && item.deadline) {
          const [time, date] = item.deadline.split(" ");
          const [day, month, year] = date.split("/").map(Number);
          const [hours, minutes] = time.split(":").map(Number);
          const deadline = new Date(year, month - 1, day, hours, minutes);

          if (!isNaN(deadline.getTime())) {
            const now = new Date();

            if (deadline.getTime() < now.getTime()) {
              deadlineText = computeElapsedTime(language, deadline.toISOString(), "TIME.", "_LATE");
              deadlineClass = "text-danger";
            } else {
              deadlineText = computeRemainingTime(language, deadline.toISOString(), "TIME.", "_LEFT");
            }
          } else {
            deadlineText = translate(language, "PAGES.ATTEMPTS.COLUMNS.DEADLINE.INVALID_DATE");
          }
        }

        return (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              {item.deadline || "N/A"}
              {item.status.toLowerCase() === "correction_pending" && item.deadline && (
                <div className={`${deadlineClass} ms-2`}>{deadlineText}</div>
              )}
            </div>
            <i className="clickable dst-icon-info-circle" onClick={() => alert("WIP")} />
          </div>
        );
      },
    },
    {
      key: "status",
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.STATUS"),
      render: (item: EvaluationAttempt) => {
        const statusColor = getStatusColor(item.status);
        return <span className={statusColor}>{item.status || "N/A"}</span>;
      },
    },
    {
      key: "ai_prevision",
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.AI_PREVISION"),
      render: (item: EvaluationAttempt) => item.ai_prevision_score || "N/A",
    },
    {
      key: "learner",
      name: translate(language, "PAGES.ATTEMPTS.COLUMNS.LEARNERS"),
      render: (item: EvaluationAttempt) => (
        <div className="d-flex justify-content-between align-items-center">
          {item.user.full_name}{" "}
          <i className="clickable dst-icon-info-circle" onClick={() => handleShowPopup(item.user)} />
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <h1 className="p-3">
        {translate(language, "PAGES.ATTEMPTS.TITLE")} ({evaluationAttempts.length})
      </h1>
      <hr />
      <div className="px-3">
        <div className="d-flex mb-3 justify-content-between">
          <div className="d-flex">
            <div className="me-2">
              <MultiFilterDropdown
                tags={programs}
                onSelectTags={setSelectedPrograms}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.PROGRAM")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={customerTypes}
                onSelectTags={setSelectedCustomerTypes}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.CUSTOMER_SEGMENT")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={intensities}
                onSelectTags={setSelectedIntensities}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.FORMAT")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={userLanguages}
                onSelectTags={setSelectedUserLanguages}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.LANGUAGE")}
                language={language}
              />
            </div>
            <div className="me-2">
              <MultiFilterDropdown
                tags={statuses}
                onSelectTags={setSelectedStatuses}
                showSearchBar
                inputTitle={translate(language, "PAGES.ATTEMPTS.FILTER.STATUS")}
                language={language}
              />
            </div>
          </div>
          <DstButton
            variant="light"
            btnImageBefore={<span className="dst-icon-settings-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.MY_FILTER")}
            clickFunction={() => alert("WIP")}
          />
        </div>
        <InputGroup>
          <DstTableSearch data={evaluationAttempts} searchableColumns={searchableColumns} onSearch={handleSearch} />
        </InputGroup>
        <div className="d-flex align-items-center justify-content-end xs-regular">
          {selectedItems.length > 0 && (
            <div
              className="me-2 clickable darkgray-font text-decoration-underline"
              onClick={() => setSelectedItems([])}
            >
              {translate(language, "PAGES.ATTEMPTS.ACTIONS.CANCEL_SELECTION")}
            </div>
          )}
          <div className="me-2 lightblue-font">
            {selectedItems.length} {translate(language, "PAGES.ATTEMPTS.CORRECTION_SELECTED")}
          </div>
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-tool-02 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_IN_PROGRESS")}
            clickFunction={() => alert("WIP")}
          />
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-flag-04 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.SET_ON_HOLD")}
            clickFunction={() => alert("WIP")}
          />
          <DstButton
            btnClass="me-2"
            variant="light"
            btnImageBefore={<span className="dst-icon-download-01 me-1" />}
            value={translate(language, "PAGES.ATTEMPTS.ACTIONS.DOWNLOAD_FILE")}
            clickFunction={() => alert("WIP")}
          />
        </div>
        <div className="d-flex">
          <p className="xs-regular darkgray-font mb-0">
            {translate(language, "PAGES.ATTEMPTS.MATCH_FILTER")
              .replace("{{NUMBER_ATTEMPTS_FILTER}}", filteredData.length.toString())
              .replace("{{NUMBER_ATTEMPTS_TOTAL}}", evaluationAttempts.length.toString())}
          </p>
          {(selectedPrograms.length > 0 ||
            selectedStatuses.length > 0 ||
            selectedUserLanguages.length > 0 ||
            selectedCustomerTypes.length > 0 ||
            selectedIntensities.length > 0) && (
            <div
              className="ms-3 clickable darkgray-font text-decoration-underline xs-regular"
              onClick={() => {
                setSelectedPrograms([]);
                setSelectedStatuses([]);
                setSelectedUserLanguages([]);
                setSelectedCustomerTypes([]);
                setSelectedIntensities([]);
              }}
            >
              {translate(language, "PAGES.ATTEMPTS.ACTIONS.CLEAR_ALL_FILTER")}
            </div>
          )}
        </div>
        {filteredData.length > 0 ? (
          <DstTable
            columns={columns}
            data={filteredData}
            selectable
            striped
            onRowSelect={setSelectedItems}
            selectedItems={selectedItems}
          />
        ) : (
          <p>{translate(language, "PAGES.ATTEMPTS.EVALUATION_NOT_FOUND")}</p>
        )}
        {selectedUser && (
          <Popup
            show={showPopup}
            onClose={handleClosePopup}
            modalTitle={translate(language, "PAGES.ATTEMPTS.USER_MODAL.TITLE")}
            size="lg"
          >
            <div>
              <p>
                <strong>{translate(language, "PAGES.ATTEMPTS.USER_MODAL.ID")}</strong> {selectedUser.id}
              </p>
              <p>
                <strong>{translate(language, "PAGES.ATTEMPTS.USER_MODAL.NAME")}</strong> {selectedUser.full_name}
              </p>
              <p>
                <strong>{translate(language, "PAGES.ATTEMPTS.USER_MODAL.EMAIL")}</strong> {selectedUser.email}
              </p>
              <p>
                <strong>{translate(language, "PAGES.ATTEMPTS.USER_MODAL.LANGUAGE")}</strong> {selectedUser.language}
              </p>
            </div>
          </Popup>
        )}
      </div>
    </React.Fragment>
  );
};
export default EvaluationAttempts;

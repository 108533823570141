import ApiService from "../../../../services/ApiService";
import { MINUTES_IN_MILLISECONDS } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { Animator, FormData, Organizer } from "./CohortMeetings.d";

const calculateEndDate = (startDate: string, duration: number) => {
  const start = new Date(startDate);
  return new Date(start.getTime() + duration * MINUTES_IN_MILLISECONDS).toISOString();
};

export const getMeetings = (cohortId: string, setMeetings: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/meetings`)
    .then((response) => {
      setMeetings(response.data.meetings);
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to get meetings:", error);
      setMeetings([]);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.GET_MEETINGS"));
    });
};

export const getAdmins = (setOrganizers: Function, setAnimators: Function, setPrograms: Function, language: string) => {
  ApiService.get("/meetings/resources")
    .then((response) => {
      const organizersWithId = response.data.meeting_staff_and_programs.organizers.map(
        (organizer: Organizer, index: number) => ({
          ...organizer,
          id: index,
          incompatible: response.data.meeting_staff_and_programs.organizers
            .filter((_: number, i: number) => i !== index)
            .map((_: number, i: number) => i),
        })
      );
      const animatorsWithId = response.data.meeting_staff_and_programs.animators.map(
        (animator: Animator, index: number) => ({
          ...animator,
          id: index,
        })
      );
      setOrganizers(organizersWithId);
      setAnimators(animatorsWithId);
      setPrograms(response.data.meeting_staff_and_programs.programs || []);
    })
    .catch((error) => {
      console.error("Failed to get animators and organizers:", error);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.GET_ADMINS"));
    });
};

export const createMeeting = (cohortId: string, formData: FormData, language: string, callback?: Function) => {
  const payload = {
    meeting_type: formData.selectedMeetingType,
    start: formData.startDate,
    end: calculateEndDate(formData.startDate, formData.duration),
    meeting_name: formData.title,
    visibility: formData.selectedVisibility,
    organizer_id: formData.selectedOrganizers,
    mc_meeting_language: formData.selectedLanguage,
    mc_meeting_program: formData.selectedProgram,
    animators: formData.selectedAnimators.map((animator: Animator) => animator.username),
    generate_zoom_link: formData.generateZoomLink,
  };

  ApiService.post(`cohorts/${cohortId}/meetings`, payload)
    .then((_) => {
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to create meeting:", error);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.CREATE_MEETING"));
    });
};

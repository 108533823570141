import React, { useEffect, useState } from "react";
import { Col, Placeholder, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstTabs from "../../components/DstTabs/DstTabs";
import Popup from "../../components/Popup/Popup";
import { useSession } from "../../contexts/SessionContext";
import ApiService from "../../services/ApiService";
import { PermissionComponent } from "../../services/PermissionGuard";
import { computeRemainingTime, formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import { extractReport, handlePedagogicalPathClick } from "./Cohort.function";
import "./Cohort.scss";
import CohortMeetings from "./components/CohortMeetings/CohortMeetings";
import CohortMessages from "./components/CohortMessages/CohortMessages";
import NoteComponent from "./components/CohortNote/CohortNote";
import CohortProjects from "./components/CohortProjects/CohortProjects";
import CohortResources from "./components/CohortResources/CohortResources";
import CohortSprint from "./components/CohortSprint/CohortSprint";
import CohortUsers from "./components/CohortUsers/CohortUsers";

const Cohort = () => {
  const { cohort_id } = useParams();
  const { language } = useSession();
  const [cohort, setCohort] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isPedagogicalPathLoading, setIsPedagogicalPathLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showResourceModal, setShowResourceModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [activeKey, setActiveKey] = useState("learners");
  const handleSelect = (key: any) => {
    if (key) {
      setActiveKey(key);
    }
  };

  useEffect(() => {
    const fetchCohort = async () => {
      try {
        const response = await ApiService.get(`cohorts/${cohort_id}`);
        const cohortData = response.data.cohort;
        const formattedCohort = {
          ...cohortData,
          cohort_leader: cohortData.cohort_leader?.full_name,
          program_manager: cohortData.program_manager?.full_name,
          start_date: formatDateHumanly(language, cohortData.dt_start),
          end_date: formatDateHumanly(language, cohortData.dt_end),
          dt_created: formatDateHumanly(language, cohortData.dt_created),
        };
        setCohort(formattedCohort);
      } catch (error) {
        console.error("Error fetching Cohort:", error);
        alert(translate(language, "PAGES.COHORT.ERROR.API_ERROR"));
      } finally {
        setLoading(false);
      }
    };

    fetchCohort();
  }, [cohort_id, language]);

  const activeSprint = cohort?.current_sprints?.[0];

  const tabData = [
    {
      id: "learners",
      title: (
        <span>
          <i className="dst-icon-users-03 me-1" />
          {translate(language, "PAGES.COHORT.TABS.LEARNERS")}
        </span>
      ),
      content: <CohortUsers cohortId={cohort_id!} language={language} />,
    },
    {
      id: "sprints",
      title: (
        <span>
          <i className="dst-icon-git-commit me-1" />
          {translate(language, "PAGES.COHORT.TABS.SPRINTS")}
        </span>
      ),
      content: <CohortSprint cohortId={cohort_id!} language={language} />,
    },
    {
      id: "meetings",
      title: (
        <span>
          <i className="dst-icon-video-recorder me-1" />
          {translate(language, "PAGES.COHORT.TABS.MEETINGS")}
        </span>
      ),
      content: <CohortMeetings cohortId={cohort_id!} language={language} />,
    },
    {
      id: "program-management",
      title: (
        <span>
          <i className="dst-icon-message-text-square-01 me-1" />
          {translate(language, "PAGES.COHORT.TABS.PROGRAM_MANAGEMENT")}
        </span>
      ),
      content: <div>WIP</div>,
    },
    {
      id: "projects",
      title: (
        <span>
          <i className="dst-icon-file-06 me-1" />
          {translate(language, "PAGES.COHORT.TABS.PROJECTS")}
        </span>
      ),
      content: <CohortProjects cohortId={cohort_id!} language={language} />,
    },
    {
      id: "notes",
      title: (
        <span>
          <i className="dst-icon-annotation me-1" />
          {translate(language, "PAGES.COHORT.TABS.NOTES")}
        </span>
      ),
      content: <NoteComponent cohortId={cohort_id!} language={language} />,
    },
    {
      id: "resources",
      title: (
        <span>
          <i className="dst-icon-link-03 me-1" />
          {translate(language, "PAGES.COHORT.TABS.RESOURCES")}
        </span>
      ),
      content: (
        <CohortResources
          cohortId={cohort_id!}
          language={language}
          closePopup={() => setShowResourceModal(false)}
          showPopup={showResourceModal}
        />
      ),
    },
    {
      id: "messages",
      title: (
        <span>
          <i className=" dst-icon-message-text-circle-02 me-1" />
          {translate(language, "PAGES.COHORT.TABS.MESSAGES")}
        </span>
      ),
      content: <CohortMessages cohortId={cohort_id!} language={language} />,
    },
  ];

  if (loading) {
    return (
      <Row className="border-bottom px-0 w-100 mx-0">
        <Col lg={9} className="d-flex row mx-0 align-items-center s-bold py-3 gap-2 px-2">
          <div className="d-flex w-fit px-0 me-3">
            <div
              className="dst-icon-arrow-circle-left h3 mb-0 blue-font me-1 clickable"
              onClick={() => navigate("/cohorts")}
            />
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "120px" }} className="h3 mb-0" />
            </Placeholder>
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.PROGRAM_MANAGER_SHORT")}</span>:
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "50px" }} className="mb-1" />
            </Placeholder>
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />{" "}
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.COHORT_LEADER_SHORT")}</span>:{" "}
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "50px" }} className="mb-1" />
            </Placeholder>
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />{" "}
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.SCHOOL")}</span>:{" "}
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "50px" }} className="mb-1" />
            </Placeholder>
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />{" "}
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.DATES")}</span>:{" "}
            <Placeholder as="span" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "100px" }} className="mb-1" />
            </Placeholder>
          </div>
          <div className="w-fit px-0">
            <Placeholder as="div" animation="glow">
              <Placeholder xs={12} role="mockup" style={{ width: "150px" }} className="mb-1" />
            </Placeholder>
          </div>
          <div className="w-fit px-0">
            <DstButton
              mock={loading}
              btnWidth="178px"
              btnImageBefore={<i className="dst-icon-link-03 me-2" />}
              value={translate(language, "PAGES.COHORT.BUTTONS.PEDAGOGICAL_TITLE")}
              variant="light"
              clickFunction={() => alert("WIP")}
            />
          </div>
        </Col>
        <Col lg={3} className="justify-content-end d-flex align-items-center py-3">
          <DstButton
            mock={loading}
            btnWidth="150px"
            btnClass="me-2"
            value={translate(language, "PAGES.COHORT.BUTTONS.SEND_MESSAGE_TITLE")}
            variant="light"
            btnImageBefore={<span className="dst-icon-send-01 me-2" />}
          />
          <DstButton
            mock={loading}
            btnClass="me-2"
            btnWidth="38px"
            value=""
            btnImageAfter={<span className="dst-icon-info-circle" />}
            clickFunction={handleShow}
            variant="light"
          />
          <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/cohort/*/report/extract" }]}>
            <DstButton
              mock={loading}
              btnWidth="38px"
              value=""
              btnImageAfter={<span className="dst-icon-download-02" />}
              variant="light"
              clickFunction={() => alert("WIP")}
            />
          </PermissionComponent>
        </Col>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} role="mockup" className="w-50 h3 mb-0" />
        </Placeholder>
      </Row>
    );
  }

  if (!cohort) {
    return (
      <div className="text-center mt-3">
        <h2>{translate(language, "PAGES.COHORT.ERROR.NOT_FOUND")}</h2>
        <DstButton
          value={translate(language, "PAGES.COHORT.BUTTONS.ALL_COHORTS_TITLE")}
          clickFunction={() => navigate("/cohorts")}
        />
      </div>
    );
  }
  return (
    <div>
      <Row className="border-bottom px-0 w-100 mx-0">
        <Col lg={9} className="d-flex row mx-0 align-items-center s-bold py-3 gap-2 px-2">
          <div className="d-flex w-fit px-0 me-3">
            <div
              className="dst-icon-arrow-circle-left h3 mb-0 blue-font me-1 clickable"
              onClick={() => navigate("/cohorts")}
            />
            <div className="h3 mb-0">{cohort.name}</div>
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.PROGRAM_MANAGER_SHORT")}</span>:{" "}
            {cohort.program_manager || "N/A"}
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-user-01 me-1" />{" "}
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.COHORT_LEADER_SHORT")}</span>:{" "}
            {cohort.cohort_leader}
          </div>
          {!!cohort.coordinators.length && (
            <div className="w-fit px-0 text-nowrap">
              <i className="dst-icon-users-01 me-1" />{" "}
              <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.COHORT_COORDINATORS_SHORT")}</span>
              : {cohort.coordinators.map((coordinator: any) => coordinator.full_name).join(", ")}
            </div>
          )}
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-building-08 me-1" />
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.SCHOOL")}</span>: {cohort.school}
          </div>
          <div className="w-fit px-0 text-nowrap">
            <i className="dst-icon-calendar me-1" />
            <span className="blue-font">{translate(language, "PAGES.COHORT.DETAILS.DATES")}</span>:{" "}
            <span title={formatDateHumanly(language, cohort.dt_start, true)}>
              {formatDateHumanly(language, cohort.dt_start)}
            </span>{" "}
            -{" "}
            <span title={formatDateHumanly(language, cohort.dt_end, true)}>
              {formatDateHumanly(language, cohort.dt_end)}
            </span>
          </div>
          <div className="w-fit px-0">
            {activeSprint && (
              <div>
                <i className="dst-icon-package me-1" />
                {activeSprint.name} (
                <span className="fst-italic">
                  {computeRemainingTime(language, activeSprint.dt_end, "TIME.", "_LEFT")})
                </span>
              </div>
            )}
          </div>
          <div className="w-fit px-0">
            <DstButton
              btnImageBefore={<i className="dst-icon-link-03 me-2" />}
              value={translate(language, "PAGES.COHORT.BUTTONS.PEDAGOGICAL_TITLE")}
              loading={isPedagogicalPathLoading}
              variant="light"
              clickFunction={() =>
                handlePedagogicalPathClick(
                  cohort_id!,
                  setShowResourceModal,
                  setActiveKey,
                  setIsPedagogicalPathLoading,
                  () => {
                    setIsPedagogicalPathLoading(false);
                  }
                )
              }
            />
          </div>
        </Col>
        <Col lg={3} className="justify-content-end d-flex align-items-center py-3">
          <DstButton
            btnClass="me-2"
            value={translate(language, "PAGES.COHORT.BUTTONS.SEND_MESSAGE_TITLE")}
            variant="light"
            btnImageBefore={<span className="dst-icon-send-01 me-2" />}
            clickFunction={() => navigate("/messages", { state: { cohortId: cohort_id, cohortName: cohort.name } })}
          />
          <DstButton
            btnClass="me-2"
            value=""
            btnImageAfter={<span className="dst-icon-info-circle" />}
            clickFunction={handleShow}
            variant="light"
          />
          <PermissionComponent neededPermission={[{ methods: ["POST"], route: "/cohort/*/report/extract" }]}>
            <DstButton
              value=""
              btnImageAfter={isDownloading ? <Spinner size="sm" /> : <span className="dst-icon-download-02" />}
              variant="light"
              clickFunction={() => {
                if (!isDownloading) {
                  setIsDownloading(true);
                  extractReport(cohort_id!, language, () => {
                    setIsDownloading(false);
                  });
                }
              }}
            />
          </PermissionComponent>
        </Col>
      </Row>
      <Popup show={showModal} onClose={handleClose} modalTitle={translate(language, "PAGES.COHORT.POPUP.TITLE")}>
        <div>
          <img src={cohort.logo} alt={cohort.name} style={{ maxWidth: "200px" }} />
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.ID")}:</strong> {cohort.id}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.NAME")}:</strong> {cohort.name}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.COHORT_LEADER")}:</strong> {cohort.cohort_leader}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.START_DATE")}:</strong> {cohort.start_date}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.END_DATE")}:</strong> {cohort.end_date}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.CUSTOMER_TYPE")}:</strong> {cohort.customer_type}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.DESCRIPTION")}:</strong> {cohort.description}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.CREATED_DATE")}:</strong> {cohort.dt_created}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.INTENSITY")}:</strong> {cohort.intensity}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.NEED_PROJECT")}:</strong>{" "}
          {cohort.need_project ? "Yes" : "No"}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.PROGRAM_MANAGER")}:</strong>{" "}
          {cohort.program_manager || "N/A"}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.COORDINATORS")}:</strong>{" "}
          {cohort.coordinators.map((coordinator: any) => coordinator.full_name).join(", ")}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.SCHOOL")}:</strong> {cohort.school}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.SESSION")}:</strong> {cohort.session}
        </div>
        <div>
          <strong>{translate(language, "PAGES.COHORT.POPUP.WORKSPACE_URL")}:</strong> {cohort.workspace_url}
        </div>
      </Popup>
      <DstTabs
        tabsData={tabData}
        activeKey={activeKey}
        onSelect={handleSelect}
        variant="primary"
        classes="additional-class"
      />
    </div>
  );
};

export default Cohort;

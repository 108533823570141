import translate from "../../services/Translate";

const UnauthorizedComponent = ({
  language,
  customClassName = [],
  customStyle = {},
}: {
  language: string;
  customClassName?: Array<string>;
  customStyle?: React.CSSProperties;
}) => {
  return (
    <div
      className={`p-3 p-lg-4 text-center w-100 gray-background ${customClassName.join(" ")}`}
      style={{ ...customStyle }}
    >
      <i className="h1 dst-icon-alert-hexagon"></i>
      <p className="mb-0">{translate(language, "COMPONENTS.UNAUTHORIZED_COMPONENT.TEXT")}</p>
    </div>
  );
};

export default UnauthorizedComponent;

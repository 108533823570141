import { formatMinutesHourly } from "../../services/TimeService";

export const getLogTimeValue = (value: string | number): string =>
  typeof value === "number" ? formatMinutesHourly(value) : value;

export const calculateNextIndex = (startIndex: number, barsToShow: number, dataLength: number) => {
  return Math.min(startIndex + barsToShow, dataLength - barsToShow);
};

export const calculatePrevIndex = (startIndex: number, barsToShow: number) => {
  return Math.max(startIndex - barsToShow, 0);
};

export const createVisibilityToggler = (
  setVisible: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  return (dataKey: string) => {
    setVisible((prevVisible) => ({
      ...prevVisible,
      [dataKey]: !prevVisible[dataKey],
    }));
  };
};

import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ListerSearch from "../../components/Lister/ListerSearch/ListerSearch";
import LiveLister from "../../components/Lister/LiveLister";
import { useSession } from "../../contexts/SessionContext";
import { formatDateHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";

export const Users = () => {
  const { language } = useSession();
  const navigate = useNavigate();
  // const [flagFilters, setFlagFilters] = useState<string[]>([]);
  // const [uniqueFlags, setUniqueFlags] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    { header: translate(language, "PAGES.USERS.ID"), key: "id", sortable: true },
    { header: translate(language, "PAGES.USERS.FIRST_NAME"), key: "first_name", sortable: true },
    { header: translate(language, "PAGES.USERS.LAST_NAME"), key: "last_name", sortable: true },
    { header: translate(language, "PAGES.USERS.EMAIL"), key: "email", sortable: true },
    {
      header: translate(language, "PAGES.USERS.ACTIVE"),
      key: "is_active",
      render: (user: any) => (
        <div
          title={`${formatDateHumanly(language, user.dt_start)} - ${formatDateHumanly(language, user.dt_end)}`}
          className="m-regular"
        >
          {user.is_active ? <i className="dst-icon-check green-font" /> : <i className="dst-icon-x red-font" />}
        </div>
      ),
    },
  ];

  return (
    <div className="m-5 position-relative">
      <div className="d-flex w-fit align-items-center justify-content-between mb-3">
        <h2 className="mb-0 me-3">Users</h2>
        <ListerSearch setSearchQuery={setSearchQuery} timeDelay={500} showSearchIcon />
        {/* <MultiFilterDropdown
          tags={uniqueFlags}
          onSelectTags={setFlagFilters}
          language={language}
          inputTitle={translate(language, "PAGES.USERS.MULTI_FILTER_DROPDOWN.TITLE")}
          inputLabel={translate(language, "PAGES.USERS.MULTI_FILTER_DROPDOWN.LABEL")}
          inputPlaceholder={translate(language, "PAGES.USERS.MULTI_FILTER_DROPDOWN.PLACEHOLDER")}
          showSearchBar
          mock={loading}
        /> */}
      </div>
      <LiveLister
        remote={`users?fetch_flags=true${searchQuery ? `&search=${searchQuery.toLowerCase()}` : ""}`}
        remoteKey="users"
        remoteTransform={(user: any) => ({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          dt_start: user.dt_start,
          dt_end: user.dt_end,
          is_active: user.is_active,
          email: user.email,
          flags: user.flags.map((flag: any) => flag.name).join(", "),
        })}
        columns={columns}
        additionalButton={(item: any) => (
          <span className="clickable xs-regular" onClick={() => navigate(`/user/${item.id}`)}>
            <span className="dst-icon-arrow-narrow-right" style={{ fontSize: "15px" }} />
          </span>
        )}
        showHeader
        pagination
        itemsPerPage={20}
      />
    </div>
  );
};

export default Users;

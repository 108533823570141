import React, { useEffect, useState } from "react";
import { Badge, Col, Dropdown, FormControl, InputGroup, Placeholder, Row } from "react-bootstrap";

import translate from "../../services/Translate";
import DstButton from "../DstButton/DstButton";
import CheckIconRounded from "../DstIcons/CheckIconRounded";
import Plus from "../DstIcons/Plus";
import { MultiSelectDropdownOptions } from "./MultiSelectDropdown.d";
import "./MultiSelectDropdown.scss";

const MultiSelectDropdown = ({
  badgeItemProperty,
  btnWidth = "120px",
  clearOnConfirm,
  clearItemsOnSend = false,
  confirmOnClose = false,
  disabled = false,
  displayItem,
  hideCaret = false,
  hideOnConfirm = true,
  items,
  label,
  language,
  mock = false,
  onConfirm,
  showAlwaysConfirm,
  toggleClass = "min-w-100px",
}: MultiSelectDropdownOptions) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState<number | null>(null);
  const _isItemDisabled = (itemId: number) => {
    return selectedItems.some((selectedItem) => selectedItem.incompatible?.includes(itemId));
  };

  const _handleSelectItem = (item: any, event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const _handleRemoveItem = (itemToRemove: any) => {
    setSelectedItems(selectedItems.filter((item) => item.id !== itemToRemove.id));
  };

  const _handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const _isItemSelected = (itemId: number) => selectedItems.some((item) => item.id === itemId);

  const _filteredItems = items.filter(
    (item) =>
      !searchTerm ||
      Object.values(item).some(
        (property: any) =>
          ["string", "number"].includes(typeof property) &&
          String(property).toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const handleConfirmClick = () => {
    onConfirm(selectedItems);
    if (clearOnConfirm) {
      setSelectedItems([]);
    }
    setDropdownOpen(!hideOnConfirm);
  };

  useEffect(() => {
    if (clearItemsOnSend) {
      setSelectedItems([]);
    }
  }, [clearItemsOnSend]);

  if (mock) {
    return (
      <Placeholder animation="glow">
        <Placeholder.Button
          className="h-100"
          style={{
            width: btnWidth,
          }}
          aria-hidden="true"
        />
      </Placeholder>
    );
  }

  return (
    <Dropdown
      className="multi-select-dropdown"
      show={dropdownOpen}
      onToggle={(isOpen) => {
        if (disabled) {
          return;
        }
        setDropdownOpen(isOpen);
        if (!isOpen && confirmOnClose) {
          onConfirm(selectedItems);
        }
      }}
    >
      <Dropdown.Toggle
        variant="input"
        className={`text-left align-items-center justify-content-between d-flex ${toggleClass} ${
          hideCaret ? "caret-hide" : ""
        }`}
      >
        {label}
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-3 min-w-450px s-regular">
        <InputGroup className="mb-3">
          <FormControl
            placeholder={translate(language, "COMPONENTS.MULTI_SELECT.SEARCH_PLACEHOLDER")}
            value={searchTerm}
            onChange={_handleSearchChange}
            className="s-regular"
          />
        </InputGroup>
        <div style={{ maxHeight: "200px", overflowY: "auto" }}>
          {_filteredItems.map((item) => (
            <Dropdown.Item
              key={item.id}
              onClick={(e) => _handleSelectItem(item, e)}
              className="px-0"
              onMouseEnter={() => setHoveredItemId(item.id)}
              onMouseLeave={() => setHoveredItemId(null)}
              onFocus={() => setHoveredItemId(item.id)}
              onBlur={() => setHoveredItemId(null)}
              disabled={_isItemDisabled(item.id)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex w-100 position-relative">
                  <div className="me-80px">{displayItem(item)}</div>
                  {_isItemSelected(item.id) && (
                    <div className="d-flex align-items-center position-absolute end-0 ">
                      <CheckIconRounded />
                      <span>{translate(language, "COMPONENTS.MULTI_SELECT.ADDED")}</span>
                    </div>
                  )}

                  {!_isItemSelected(item.id) && hoveredItemId === item.id && (
                    <div className="position-absolute end-0">
                      <Plus color="#F2EFFF" bgColor="#4628DD" />
                    </div>
                  )}
                </div>
              </div>
            </Dropdown.Item>
          ))}
        </div>
        <Row>
          <Col md={9}>
            <div className="selected-items mt-2">
              {selectedItems.map((item, index) => (
                <Badge
                  key={`chosen-${index}`}
                  pill
                  className="selected-item-badge me-1 d-inline-flex w-fit align-items-center"
                  bg="dropdown"
                >
                  <span>{item[badgeItemProperty]}</span>
                  <i className="dst-icon-x clickable" onClick={() => _handleRemoveItem(item)}></i>
                </Badge>
              ))}
              <div className="xs-regular">
                {selectedItems.length}{" "}
                {selectedItems.length > 1
                  ? translate(language, "COMPONENTS.MULTI_SELECT.ITEMS")
                  : translate(language, "COMPONENTS.MULTI_SELECT.ITEM")}{" "}
                {translate(language, "COMPONENTS.MULTI_SELECT.SELECTED")}
              </div>
            </div>
          </Col>
          <Col md={3} className="text-end align-self-end">
            {(showAlwaysConfirm || selectedItems.length > 0) && !confirmOnClose && (
              <DstButton
                btnClass="mt-2"
                clickFunction={handleConfirmClick}
                value={translate(language, "PAGES.USER.OVERVIEW.MODULE.CONFIRM")}
                btnSize="lg"
              />
            )}
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectDropdown;

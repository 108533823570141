import { Note } from "../../models/Note";
import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { getLegacyAdminHostname } from "../../services/UtilityService";

export const loginAs = (user_id: string) => () => {
  ApiService.get(`auth/login-as/${user_id}`)
    .then((response) => window.open(response.data.url, "_blank"))
    .catch((error) => console.error("loginAs", error));
};

export const logout = (user_id: string) => () => {
  ApiService.post(`admin/auth/logout-user?user_id=${user_id}`, {})
    .then((_) => alert("User has been logged out."))
    .catch((error) => console.error("logout", error));
};

export const openDocument = (type: string, filename: string) => {
  window.open(`${process.env.REACT_APP_FILE_URL}/${type}/${filename}`, "_blank");
};

export const openEmail = (email: any, setShow: Function, setContent: Function) => {
  setContent(email.body);
  setShow(true);
};

export const displayDate = (value: string) => {
  try {
    return new Date(value).toISOString().slice(0, 16);
  } catch (_) {
    return String(value);
  }
};

export const fetchEditionElements = async (setCohorts: Function, setFlags: Function, showLoaded: Function) => {
  try {
    await Promise.all([ApiService.get("cohorts"), ApiService.get("flags")]).then((responses) => {
      const cohorts = responses[0].data.cohorts;
      const flags = responses[1].data.flags;
      setCohorts(cohorts);
      setFlags(flags);
      showLoaded();
    });
  } catch (err) {
    console.error("An error occurred for fetching all flags", err);
  }
};

export const fetchModules = (setModules: Function) => {
  ApiService.get("modules?get_deprecated=false")
    .then((response) => {
      setModules(response.data.return.modules);
    })
    .catch((err) => {
      console.error("An error occurred while fetching modules", err);
    });
};

export const updateUser = async (
  userData: any,
  editedUserData: any,
  setEditionLoading: Function,
  refreshUserBlock: Function,
  author_id?: number | null
) => {
  let editions: any = {};
  setEditionLoading(true);

  Object.keys(userData).map((key: string) => {
    if (JSON.stringify(userData[key]) !== JSON.stringify(editedUserData[key])) {
      switch (key) {
        case "user_cohorts":
          editions.cohorts = {
            cohort_ids: [...editedUserData[key]].map((element) => element.id),
          };
          break;
        case "user_flags":
          editions.flags = {
            flag_ids: [...editedUserData[key]].map((element) => element.id),
            author_id,
          };
          break;
        default:
          editions.user = {
            ...editions.user,
            [key]: ["dt_start", "dt_end"].includes(key) ? ~~(editedUserData[key] / 1000) : editedUserData[key],
          };
          break;
      }
      return true;
    }
    return false;
  });

  Promise.all(
    Object.keys(editions).map((key_to_edit: string) => {
      switch (key_to_edit) {
        case "user":
          return ApiService.patch(`users/${userData.id}`, editions.user);
        case "cohorts":
          return ApiService.put(`users/${userData.id}/cohorts`, editions.cohorts);
        case "flags":
          return ApiService.put(`users/${userData.id}/flags`, editions.flags);
        default:
          break;
      }
      return true;
    })
  )
    .then(
      (_) => {
        refreshUserBlock();
      },
      (err) => {
        alert("An error occurred on editing user, more details in console. Contact Developer Team");
        console.error(err);
      }
    )
    .finally(() => {
      setEditionLoading(false);
    });
};

export const createNote = (
  userId: number,
  authorEmail: string,
  language: string,
  setUserData: Function,
  setActiveNote: Function,
  setIsEditing: Function
) => {
  ApiService.post(`users/${userId}/notes`, {
    author_email: authorEmail,
    value: "",
  })
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      setActiveNote(resp.data.notes?.sort((a: Note, b: Note) => b.dt_created - a.dt_created)?.[0]);
      setIsEditing(true);
    })
    .catch((error) => {
      if (error.status === 400) {
        alert(translate(language, "COMPONENTS.USER.ERROR_NOTE_TOO_BIG"));
        console.error(error);
      } else {
        alert(translate(language, "COMPONENTS.USER.ERROR"));
        console.error(error);
      }
    });
};

export const deleteNote = (userId: number, noteId: string, setUserData: Function, callback?: Function) => {
  ApiService.delete(`users/${userId}/notes/${noteId}`)
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback();
    })
    .catch((err) => {
      alert("An error occurred on deleting note, more details in console. Contact Developer Team");
      console.error(err);
    });
};

export const updateNote = (
  userId: number,
  authorEmail: string,
  language: string,
  note: Note,
  setUserData: Function,
  callback?: Function
) => {
  const body = {
    author_email: authorEmail,
  } as any;

  if (note.value) {
    body.value = note.value;
  }
  if (note.title) {
    body.title = note.title;
  }

  ApiService.put(`users/${userId}/notes/${note.id}`, body)
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback(resp.data.notes.find((new_note: Note) => new_note.id === note.id));
    })
    .catch((error) => {
      if (error.status === 400) {
        alert(translate(language, "COMPONENTS.USER.ERROR_NOTE_TOO_BIG"));
        console.error(error);
      } else {
        alert(translate(language, "COMPONENTS.USER.ERROR"));
        console.error(error);
      }
    });
};

export const togglePinNote = (userId: number, note: Note, setUserData: Function, callback?: Function) => {
  (!note.is_pinned
    ? ApiService.delete(`users/${userId}/notes/${note.id}/pin`)
    : ApiService.post(`users/${userId}/notes/${note.id}/pin`, {})
  )
    .then((resp) => {
      setUserData((previous_user_data: any) => ({ ...previous_user_data, user_notes: resp.data.notes }));
      if (callback) callback(resp.data.notes.find((new_note: Note) => new_note.id === note.id));
    })
    .catch((err) => {
      alert("An error occurred on pinning/unpinning note, more details in console. Contact Developer Team");
      console.error(err);
    });
};

export const updateUserModules = (userId: number, moduleIds: any[], callback: Function) => {
  ApiService.put(`users/${userId}/modules`, { module_ids: moduleIds.map((module) => module.id) })
    .then((_response) => {
      alert("Module updated successfully");
      callback();
    })
    .catch((error) => {
      console.error("Error updating module", error);
    });
};

export const truncateMessageContent = (content: string): string => {
  content = content.replace(/<\/p>/g, "</p> ").replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gm, "");
  if (content.length > 100) content = content.slice(0, 99) + "…";
  return content;
};

export const getEvaluationLink = (evaluationId: number) => `${getLegacyAdminHostname()}/eval/user/${evaluationId}`;

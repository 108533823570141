const LogOut = ({ color = "white", classes = "me-2" }: { color?: string; classes?: string }) => (
  <svg className={classes} height="1em" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.98242 12.25H3.64909C3.33967 12.25 3.04292 12.1271 2.82413 11.9083C2.60534 11.6895 2.48242 11.3928 2.48242 11.0833V2.91667C2.48242 2.60725 2.60534 2.3105 2.82413 2.09171C3.04292 1.87292 3.33967 1.75 3.64909 1.75H5.98242"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0654 9.91683L12.9821 7.00016L10.0654 4.0835"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M12.9824 7H5.98242" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LogOut;

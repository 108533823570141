import React, { useEffect, useState } from "react";
import { Placeholder } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import { formatDateHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { completeNavigate, getLegacyAdminHostname } from "../../../../services/UtilityService";
import { fetchProjects } from "./CohortProjects.function";
import "./CohortProjects.scss";

export const CohortProjects = ({ cohortId, language }: { cohortId: string; language: string }) => {
  const navigate = useNavigate();
  const columns = [
    { name: translate(language, "PAGES.COHORT.PROJECTS.NAME"), key: "name" },
    { name: translate(language, "PAGES.COHORT.PROJECTS.SUBJECT"), key: "type_name" },
    { name: translate(language, "PAGES.COHORT.PROJECTS.PROJECT_LEADER"), key: "overseer" },
    {
      name: translate(language, "PAGES.COHORT.PROJECTS.START_DATE"),
      key: "start_dt",
      render: (elem: any) => formatDateHumanly(language, elem.start_dt),
    },
    {
      name: translate(language, "PAGES.COHORT.PROJECTS.END_DATE"),
      key: "end_dt",
      render: (elem: any) => formatDateHumanly(language, elem.end_dt),
    },
  ];

  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [cohortProjects, setCohortProjects] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProjects(cohortId, setCohortProjects, language, () => {
      setLoading(false);
    });
  }, [cohortId, language]);

  const preProcessedData = cohortProjects.map((item: any) => ({ ...item, columns }));

  if (loading) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
        </div>
      </Placeholder>
    );
  }

  return (
    <div className="container-projects-cohort px-3">
      <div className="d-flex justify-content-between mb-3 w-100">
        <div className="d-flex">
          <h2 className="text-center me-3">{translate(language, "PAGES.COHORT.PROJECTS.TITLE")}</h2>
          <DstTableSearch
            data={preProcessedData}
            searchableColumns={["name", "type_name", "overseer", "start_dt", "end_dt"]}
            onSearch={setFilteredData}
          />
        </div>
        <DstButton
          variant="light"
          value={translate(language, "PAGES.COHORT.PROJECTS.BUTTON_REFRESH")}
          btnImageBefore={<span className="dst-icon-refresh-cw-04 pe-2 align-text-bottom" />}
          clickFunction={() => {
            setLoading(true);
            fetchProjects(cohortId, setCohortProjects, language, () => {
              setLoading(false);
            });
          }}
        />
      </div>
      {cohortProjects.length === 0 ? (
        <div className="mt-3 text-muted">{translate(language, "PAGES.COHORT.PROJECTS.NO_PROJECTS_FOUND")}</div>
      ) : (
        <DstTable
          data={filteredData}
          columns={columns}
          onClick={(event, item) =>
            completeNavigate(event, navigate, `${getLegacyAdminHostname()}/group_projects/${item.id}`)
          }
        />
      )}
    </div>
  );
};

export default CohortProjects;

const Schema = ({
  color = "#8F8FA8",
  classes = "me-2",
  height = "1em",
}: {
  color?: string;
  classes?: string;
  height?: string;
}) => (
  <svg className={classes} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.43766 7.08317H7.79183V9.20817H7.43766C6.84975 9.20817 6.37516 9.68275 6.37516 10.2707V10.979H4.9585V10.2707C4.9585 9.68275 4.48391 9.20817 3.896 9.20817H1.771C1.18308 9.20817 0.708496 9.68275 0.708496 10.2707L0.708496 13.104C0.708496 13.6919 1.18308 14.1665 1.771 14.1665H3.896C4.48391 14.1665 4.9585 13.6919 4.9585 13.104V12.3957H6.37516V13.104C6.37516 13.6919 6.84975 14.1665 7.43766 14.1665H9.56266C10.1506 14.1665 10.6252 13.6919 10.6252 13.104V12.3957H12.0418V13.104C12.0418 13.6919 12.5164 14.1665 13.1043 14.1665L15.2293 14.1665C15.8172 14.1665 16.2918 13.6919 16.2918 13.104V10.2707C16.2918 9.68275 15.8172 9.20817 15.2293 9.20817H13.1043C12.5164 9.20817 12.0418 9.68275 12.0418 10.2707V10.979H10.6252V10.2707C10.6252 9.68275 10.1506 9.20817 9.56266 9.20817H9.2085V7.08317H9.56266C10.1506 7.08317 10.6252 6.60859 10.6252 6.02067V3.18734C10.6252 2.59942 10.1506 2.12483 9.56266 2.12483H7.43766C6.84975 2.12483 6.37516 2.59942 6.37516 3.18734V6.02067C6.37516 6.60859 6.84975 7.08317 7.43766 7.08317Z"
      fill={color}
    />
  </svg>
);

export default Schema;

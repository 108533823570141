import React from "react";
import { Placeholder } from "react-bootstrap";

import { Note } from "../../models/Note";
import { getAgeHumanly } from "../../services/TimeService";
import translate from "../../services/Translate";
import "./NoteListOverview.scss";

const _htmlToText = (html: string) => {
  const spacedHtml = html.replace(/<\/[^>]+>/g, " ");
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = spacedHtml;
  return tempDiv.textContent || tempDiv.innerText || "";
};

const _getPreviewContent = (content: string) => {
  const plainText = _htmlToText(content);
  return plainText.length > 150 ? `${plainText.substring(0, 150)}...` : plainText;
};

const NotesListOverview = ({
  notes = [],
  language,
  mock,
  selectNote = () => {},
  setActiveKey = () => {},
}: {
  notes?: Note[];
  language: string;
  mock?: boolean;
  selectNote?: Function;
  setActiveKey?: Function;
}) =>
  mock ? (
    <React.Fragment>
      {[...Array(5)].map((_, index) => (
        <Placeholder animation="glow" key={`note-overview-list-item-${index}`}>
          {index > 0 && <hr className="my-1" />}
          <div className="user-select-none p-1">
            <div className="s-bold gray-text-font">
              <div className="d-flex justify-content-between">
                <Placeholder as="span" xs={8} />
                <Placeholder as="span" xs={3} />
              </div>
            </div>
            <Placeholder xs={12} />
          </div>
        </Placeholder>
      ))}
    </React.Fragment>
  ) : (
    <div className="clickable" onClick={() => setActiveKey("notes")} id="note-list-overview">
      {/*<div className="d-flex p-2 blue-font lightblue-background justify-content-between">
        <span className="s-bold">Write a quick new note...</span>
        <Plus color="#F2EFFF" bgColor="#4628DD"/>
  </div>*/}

      {notes.map((note, index) => (
        <React.Fragment key={`note-overview-list-item-${index}`}>
          {index > 0 && <hr className="my-1" />}
          <div key={note.id} onClick={() => selectNote(note)} className="user-select-none p-1">
            <div className="s-regular gray-text-font">
              <div className="d-flex justify-content-between">
                <span>
                  {translate(language, "PAGES.USER.NOTE.NOTE_BY")}{" "}
                  <b className="s-bold">
                    {note.author_name ? note.author_name : translate(language, "PAGES.USER.NOTE.UNKNOWN_USER")}
                  </b>
                </span>
                <span className="lightgray-font">{getAgeHumanly(language, note.dt_created)}</span>
              </div>
            </div>
            <p className="xs-regular mb-0 overflow-hidden text-truncate">{_getPreviewContent(note.value)}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );

export default NotesListOverview;

export const getExamStatusStyle = (examStatus: string) => {
  switch (examStatus) {
    case "success":
      return "success";
    case "fail":
      return "danger";
    case "repass":
      return "warning";
    default:
      return "light";
  }
};

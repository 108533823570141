import translate from "./Translate";

export const MINUTES_IN_MILLISECONDS = 60 * 1000;
export const HOURS_IN_MILLISECONDS = 60 * MINUTES_IN_MILLISECONDS;
export const DAYS_IN_MILLISECONDS = 24 * HOURS_IN_MILLISECONDS;
export const WEEKS_IN_MILLISECONDS = 7 * DAYS_IN_MILLISECONDS;

/**
 * Says if the date time is in the past
 * @param dt_end Date time
 * @returns if the date time is in the past
 * @example
 * isPast("1970/01/01 12:00:00") // -> true
 * isPast("2970/01/01 12:00:00") // -> false
 */
export const isPast = (dt_end: string): boolean => new Date(dt_end).getTime() < Date.now();

/**
 * Format minutes in hours string
 * @param minutes minutes count
 * @returns sentence containing the same amount of minutes in format "XXhXX" or "XXmin"
 * @example
 * formatMinutesHourly(90) // -> "1h30"
 * formatMinutesHourly(60) // -> "1h00"
 * formatMinutesHourly(30) // -> "30min"
 */
export const formatMinutesHourly = (minutes: number): string => {
  return `${minutes / 60 >= 1 ? `${~~(minutes / 60)}h` : ""}${("00" + (minutes % 60)).slice(-2)}${
    minutes / 60 < 1 ? "min" : ""
  }`;
};

/**
 * Format a date or time difference humanly.
 * If the time difference is less than 1 day, it returns the time elapsed in minutes or hours ago.
 * Otherwise, it returns the date in the format 'dd/mm/yyyy'.
 *
 * @param dateCreation The timestamp representing the creation date.
 * @returns A string representing the formatted date or time difference.
 *
 * @example
 * getAgeHumanly(1707295178000) // -> "07/02/2024"
 * getAgeHumanly(1707295178000) // -> "5 hours ago"
 * getAgeHumanly(1707295178000) // -> "2 minutes ago"
 */
export const getAgeHumanly = (language: string, dateCreation: number, showMinutes = false): string => {
  const millisecondsSinceCreation = Date.now() - dateCreation;

  const minutesSinceCreation = millisecondsSinceCreation / MINUTES_IN_MILLISECONDS;
  const hoursSinceCreation = millisecondsSinceCreation / HOURS_IN_MILLISECONDS;
  const daysSinceCreation = millisecondsSinceCreation / DAYS_IN_MILLISECONDS;

  if (daysSinceCreation >= 1) {
    return formatDateHumanly(language, new Date(dateCreation).toISOString(), showMinutes);
  } else if (hoursSinceCreation >= 1) {
    return translate(language, "TIME.AGO").replace(
      "{{TIME}}",
      `${Math.round(hoursSinceCreation)} ${translate(language, hoursSinceCreation > 1 ? "TIME.HOURS" : "TIME.HOUR")}`
    );
  } else if (Math.round(minutesSinceCreation) === 0) {
    return translate(language, "TIME.JUST_NOW");
  } else {
    return translate(language, "TIME.AGO").replace(
      "{{TIME}}",
      `${Math.round(minutesSinceCreation)} ${translate(
        language,
        minutesSinceCreation > 1 ? "TIME.MINUTES" : "TIME.MINUTE"
      )}`
    );
  }
};

/**
 * Format datetime in date string
 * @param language session's language
 * @param date date string
 * @param includeTimezoneInfo Flag indicating whether to include timezone information (default: false).
 * @returns sentence containing the date compliant with human view
 * @example
 * formatDateHumanly('en-US', '2023-08-09T17:00:00+00:00') // -> "August 09, 2023"
 * formatDateHumanly('en-US', '2023-08-09T17:00:00+00:00', true) // -> "August 09, 2023 5:00 PM (UTC+2)"
 * formatDateHumanly('fr-FR', '2023-08-09T17:00:00+00:00') // -> "09 août 2023"
 * formatDateHumanly('fr-FR', '2023-08-09T17:00:00+00:00', true) // -> "09 août 2023 17:00 (UTC+2)"
 */
export const formatDateHumanly = (language: string, date: string, includeTimezoneInfo: boolean = false): string => {
  const datetime = new Date(date),
    formattedDate = datetime.toLocaleDateString(language, {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });

  if (includeTimezoneInfo) {
    const timeZone = (-1 * datetime.getTimezoneOffset()) / 60,
      formattedTime = datetime.toLocaleTimeString(language, {
        timeStyle: "short",
      });
    return `${formattedDate} ${formattedTime} (UTC${timeZone >= 0 ? "+" : ""}${timeZone})`;
  } else {
    return formattedDate;
  }
};

/**
 * Converts date time to timestamp in UTC
 * @param datetime Date time
 * @returns the date time in UTC timestamp
 */
export const getUTCTime = (datetime: string): number =>
  new Date(datetime).getTime() - new Date(datetime).getTimezoneOffset() * MINUTES_IN_MILLISECONDS;

/**
 * Format datetime difference in hours:minutes:seconds string
 * @param language session's language
 * @param dateString2 second date string
 * @returns sentence containing the absolute difference in hours:minutes:seconds format
 * @example
 * formatDateDifference('2023-08-09T17:00:00+00:00', '2023-08-09T17:00:00+00:00') // -> '00:00:00'
 * formatDateDifference('2023-08-09T17:00:00+00:00', '2023-08-09T18:00:00+00:00') // -> '01:00:00'
 * formatDateDifference('2023-08-09T17:00:40+00:00', '2023-08-09T17:00:00+00:00') // -> '00:00:40'
 */
export const formatDateDifference = (dateString1: string, dateString2: string): string => {
  const date1 = new Date(dateString1);
  const date2 = new Date(dateString2);

  if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
    return "00:00:00";
  }

  const timeDifference = Math.abs(date2.getTime() - date1.getTime());
  const hours = Math.floor(timeDifference / HOURS_IN_MILLISECONDS);
  const minutes = Math.floor((timeDifference % HOURS_IN_MILLISECONDS) / MINUTES_IN_MILLISECONDS);
  const seconds = Math.floor((timeDifference % MINUTES_IN_MILLISECONDS) / 1000);
  return `${("00" + hours).slice(-2)}:${("00" + minutes).slice(-2)}:${("00" + seconds).slice(-2)}`;
};

export function convertDateFormat(dateStr: string) {
  const [year, month, day] = dateStr.split("/").map((num) => parseInt(num, 10));
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;
  return `${formattedDay}/${formattedMonth}/${year}`;
}

export const getDays = (language: string) => {
  const days = new Array(7).fill(null).map((_, i) => {
    const day = new Intl.DateTimeFormat(language, { weekday: "short" }).format(new Date(1970, 0, i + 4));
    return day.charAt(0).toUpperCase() + day.slice(1).replace(".", "");
  });
  return days;
};

export const getMonths = (language: string) => {
  const months = new Array(12).fill(null).map((_, i) => {
    const month = new Intl.DateTimeFormat(language, { month: "short" }).format(new Date(1970, i));
    return month.charAt(0).toUpperCase() + month.slice(1).replace(".", "");
  });
  return months;
};

export const computeRemainingTime = (
  language: string,
  datetime: string | null | undefined,
  translatePrefixe: string,
  translateSuffixe: string
): string => {
  if (!datetime) return "";

  const remainingDays = ~~((new Date(datetime).getTime() - Date.now()) / DAYS_IN_MILLISECONDS);

  if (remainingDays >= 1) {
    return translate(language, `${translatePrefixe}DAY${remainingDays > 1 ? "S" : ""}${translateSuffixe}`).replace(
      "{time}",
      `${remainingDays}`
    );
  } else {
    const remainingHours = ~~((new Date(datetime).getTime() - Date.now()) / HOURS_IN_MILLISECONDS);
    if (remainingHours >= 1) {
      return translate(language, `${translatePrefixe}HOUR${remainingHours > 1 ? "S" : ""}${translateSuffixe}`).replace(
        "{time}",
        `${remainingHours}`
      );
    } else {
      const remainingMinutes = ~~((new Date(datetime).getTime() - Date.now()) / MINUTES_IN_MILLISECONDS);
      return translate(
        language,
        `${translatePrefixe}MINUTE${remainingMinutes > 1 ? "S" : ""}${translateSuffixe}`
      ).replace("{time}", `${remainingMinutes}`);
    }
  }
};

export const computeElapsedTime = (
  language: string,
  datetime: string | null | undefined,
  translatePrefixe: string,
  translateSuffixe: string
): string => {
  if (!datetime) return "";

  const elapsedMilliseconds = Date.now() - new Date(datetime).getTime();
  const elapsedDays = ~~(elapsedMilliseconds / DAYS_IN_MILLISECONDS);

  if (elapsedDays >= 1) {
    return translate(language, `${translatePrefixe}DAY${elapsedDays > 1 ? "S" : ""}${translateSuffixe}`).replace(
      "{time}",
      `${elapsedDays}`
    );
  } else {
    const elapsedHours = ~~(elapsedMilliseconds / HOURS_IN_MILLISECONDS);
    if (elapsedHours >= 1) {
      return translate(language, `${translatePrefixe}HOUR${elapsedHours > 1 ? "S" : ""}${translateSuffixe}`).replace(
        "{time}",
        `${elapsedHours}`
      );
    } else {
      const elapsedMinutes = ~~(elapsedMilliseconds / MINUTES_IN_MILLISECONDS);
      return translate(
        language,
        `${translatePrefixe}MINUTE${elapsedMinutes > 1 ? "S" : ""}${translateSuffixe}`
      ).replace("{time}", `${elapsedMinutes}`);
    }
  }
};

/**
 * Convert a date string to ISO 8601 format (YYYY-MM-DDTHH:MM)
 * @param dateString The input date string
 * @returns The formatted date string in ISO 8601 format (YYYY-MM-DDTHH:MM)
 * @example
 * formatToISODateTime('2023-08-09T17:00:00+00:00') // -> '2023-08-09 17:00'
 */
export const formatToISODateTime = (dateString: string) =>
  new Date(dateString).toISOString().slice(0, 16).replace("T", " ");

const TimeService = {
  WEEKS_IN_MILLISECONDS,
  DAYS_IN_MILLISECONDS,
  HOURS_IN_MILLISECONDS,
  MINUTES_IN_MILLISECONDS,
  formatMinutesHourly,
  formatDateHumanly,
  computeRemainingTime,
  getAgeHumanly,
  getDays,
  getMonths,
};

export default TimeService;

import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const extractReport = (cohortId: string, language: string, finalCallback: Function) => {
  ApiService.postDownload(`cohort/${cohortId}/report/extract`, {})
    .then((response: any) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `report_cohort_${cohortId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error: any) => {
      console.error(error);
      alert(translate(language, "PAGES.COHORT.EXTRACT_REPORT.ERROR"));
    })
    .finally(() => finalCallback());
};

export const handlePedagogicalPathClick = (
  cohort_id: string,
  setShowPopup: Function,
  setActiveKey: Function,
  setIsPedagogicalPathLoading: Function,
  callback?: Function
) => {
  setIsPedagogicalPathLoading(true);
  ApiService.get(`cohorts/${cohort_id}/pedagogical_path`)
    .then((resp) => {
      window.open(resp.data.pedagogical_path.url, "_blank");
      callback?.();
    })
    .catch((err) => {
      setShowPopup(true);
      setActiveKey("resources");
    })
    .finally(() => setIsPedagogicalPathLoading(false));
};
